!(function (e) {
    if ('object' == typeof exports && 'undefined' != typeof module) module.exports = e();
    else if ('function' == typeof define && define.amd) define([], e);
    else {
        var f;
        'undefined' != typeof window
            ? (f = window)
            : 'undefined' != typeof global
            ? (f = global)
            : 'undefined' != typeof self && (f = self),
            (f.blobStream = e());
    }
})(function () {
    var define, module, exports;
    return (function e(t, n, r) {
        function s(o, u) {
            if (!n[o]) {
                if (!t[o]) {
                    var a = typeof require == 'function' && require;
                    if (!u && a) return a(o, !0);
                    if (i) return i(o, !0);
                    var f = new Error("Cannot find module '" + o + "'");
                    throw ((f.code = 'MODULE_NOT_FOUND'), f);
                }
                var l = (n[o] = { exports: {} });
                t[o][0].call(
                    l.exports,
                    function (e) {
                        var n = t[o][1][e];
                        return s(n ? n : e);
                    },
                    l,
                    l.exports,
                    e,
                    t,
                    n,
                    r,
                );
            }
            return n[o].exports;
        }
        var i = typeof require == 'function' && require;
        for (var o = 0; o < r.length; o++) s(r[o]);
        return s;
    })(
        {
            1: [
                function (require, module, exports) {
                    (function (global) {
                        /**
                         * Create a blob builder even when vendor prefixes exist
                         */

                        var BlobBuilder =
                            global.BlobBuilder ||
                            global.WebKitBlobBuilder ||
                            global.MSBlobBuilder ||
                            global.MozBlobBuilder;

                        /**
                         * Check if Blob constructor is supported
                         */

                        var blobSupported = (function () {
                            try {
                                var a = new Blob(['hi']);
                                return a.size === 2;
                            } catch (e) {
                                return false;
                            }
                        })();

                        /**
                         * Check if Blob constructor supports ArrayBufferViews
                         * Fails in Safari 6, so we need to map to ArrayBuffers there.
                         */

                        var blobSupportsArrayBufferView =
                            blobSupported &&
                            (function () {
                                try {
                                    var b = new Blob([new Uint8Array([1, 2])]);
                                    return b.size === 2;
                                } catch (e) {
                                    return false;
                                }
                            })();

                        /**
                         * Check if BlobBuilder is supported
                         */

                        var blobBuilderSupported =
                            BlobBuilder && BlobBuilder.prototype.append && BlobBuilder.prototype.getBlob;

                        /**
                         * Helper function that maps ArrayBufferViews to ArrayBuffers
                         * Used by BlobBuilder constructor and old browsers that didn't
                         * support it in the Blob constructor.
                         */

                        function mapArrayBufferViews(ary) {
                            for (var i = 0; i < ary.length; i++) {
                                var chunk = ary[i];
                                if (chunk.buffer instanceof ArrayBuffer) {
                                    var buf = chunk.buffer;

                                    // if this is a subarray, make a copy so we only
                                    // include the subarray region from the underlying buffer
                                    if (chunk.byteLength !== buf.byteLength) {
                                        var copy = new Uint8Array(chunk.byteLength);
                                        copy.set(new Uint8Array(buf, chunk.byteOffset, chunk.byteLength));
                                        buf = copy.buffer;
                                    }

                                    ary[i] = buf;
                                }
                            }
                        }

                        function BlobBuilderConstructor(ary, options) {
                            options = options || {};

                            var bb = new BlobBuilder();
                            mapArrayBufferViews(ary);

                            for (var i = 0; i < ary.length; i++) {
                                bb.append(ary[i]);
                            }

                            return options.type ? bb.getBlob(options.type) : bb.getBlob();
                        }

                        function BlobConstructor(ary, options) {
                            mapArrayBufferViews(ary);
                            return new Blob(ary, options || {});
                        }

                        module.exports = (function () {
                            if (blobSupported) {
                                return blobSupportsArrayBufferView ? global.Blob : BlobConstructor;
                            } else if (blobBuilderSupported) {
                                return BlobBuilderConstructor;
                            } else {
                                return undefined;
                            }
                        })();
                    }.call(
                        this,
                        typeof global !== 'undefined'
                            ? global
                            : typeof self !== 'undefined'
                            ? self
                            : typeof window !== 'undefined'
                            ? window
                            : {},
                    ));
                },
                {},
            ],
            2: [
                function (require, module, exports) {
                    (function (global) {
                        var WritableStream = require('stream').Writable;
                        var util = require('util');
                        var Blob = require('blob');
                        var URL = global.URL || global.webkitURL || global.mozURL;

                        function BlobStream() {
                            if (!(this instanceof BlobStream)) return new BlobStream();

                            WritableStream.call(this);
                            this._chunks = [];
                            this._blob = null;
                            this.length = 0;
                        }

                        util.inherits(BlobStream, WritableStream);

                        BlobStream.prototype._write = function (chunk, encoding, callback) {
                            // convert chunks to Uint8Arrays (e.g. Buffer when array fallback is being used)
                            if (!(chunk instanceof Uint8Array)) chunk = new Uint8Array(chunk);

                            this.length += chunk.length;
                            this._chunks.push(chunk);
                            callback();
                        };

                        BlobStream.prototype.toBlob = function (type) {
                            type = type || 'application/octet-stream';

                            // cache the blob if needed
                            if (!this._blob) {
                                this._blob = new Blob(this._chunks, {
                                    type: type,
                                });

                                this._chunks = []; // free memory
                            }

                            // if the cached blob's type doesn't match the requested type, make a new blob
                            if (this._blob.type !== type) this._blob = new Blob([this._blob], { type: type });

                            return this._blob;
                        };

                        BlobStream.prototype.toBlobURL = function (type) {
                            return URL.createObjectURL(this.toBlob(type));
                        };

                        module.exports = BlobStream;
                    }.call(
                        this,
                        typeof global !== 'undefined'
                            ? global
                            : typeof self !== 'undefined'
                            ? self
                            : typeof window !== 'undefined'
                            ? window
                            : {},
                    ));
                },
                { blob: 1, stream: 22, util: 25 },
            ],
            3: [
                function (require, module, exports) {
                    /*!
                     * The buffer module from node.js, for the browser.
                     *
                     * @author   Feross Aboukhadijeh <feross@feross.org> <http://feross.org>
                     * @license  MIT
                     */

                    var base64 = require('base64-js');
                    var ieee754 = require('ieee754');
                    var isArray = require('is-array');

                    exports.Buffer = Buffer;
                    exports.SlowBuffer = Buffer;
                    exports.INSPECT_MAX_BYTES = 50;
                    Buffer.poolSize = 8192; // not used by this implementation

                    var kMaxLength = 0x3fffffff;

                    /**
                     * If `Buffer.TYPED_ARRAY_SUPPORT`:
                     *   === true    Use Uint8Array implementation (fastest)
                     *   === false   Use Object implementation (most compatible, even IE6)
                     *
                     * Browsers that support typed arrays are IE 10+, Firefox 4+, Chrome 7+, Safari 5.1+,
                     * Opera 11.6+, iOS 4.2+.
                     *
                     * Note:
                     *
                     * - Implementation must support adding new properties to `Uint8Array` instances.
                     *   Firefox 4-29 lacked support, fixed in Firefox 30+.
                     *   See: https://bugzilla.mozilla.org/show_bug.cgi?id=695438.
                     *
                     *  - Chrome 9-10 is missing the `TypedArray.prototype.subarray` function.
                     *
                     *  - IE10 has a broken `TypedArray.prototype.subarray` function which returns arrays of
                     *    incorrect length in some situations.
                     *
                     * We detect these buggy browsers and set `Buffer.TYPED_ARRAY_SUPPORT` to `false` so they will
                     * get the Object implementation, which is slower but will work correctly.
                     */
                    Buffer.TYPED_ARRAY_SUPPORT = (function () {
                        try {
                            var buf = new ArrayBuffer(0);
                            var arr = new Uint8Array(buf);
                            arr.foo = function () {
                                return 42;
                            };
                            return (
                                42 === arr.foo() && // typed array instances can be augmented
                                typeof arr.subarray === 'function' && // chrome 9-10 lack `subarray`
                                new Uint8Array(1).subarray(1, 1).byteLength === 0
                            ); // ie10 has broken `subarray`
                        } catch (e) {
                            return false;
                        }
                    })();

                    /**
                     * Class: Buffer
                     * =============
                     *
                     * The Buffer constructor returns instances of `Uint8Array` that are augmented
                     * with function properties for all the node `Buffer` API functions. We use
                     * `Uint8Array` so that square bracket notation works as expected -- it returns
                     * a single octet.
                     *
                     * By augmenting the instances, we can avoid modifying the `Uint8Array`
                     * prototype.
                     */
                    function Buffer(subject, encoding, noZero) {
                        if (!(this instanceof Buffer)) return new Buffer(subject, encoding, noZero);

                        var type = typeof subject;

                        // Find the length
                        var length;
                        if (type === 'number') length = subject > 0 ? subject >>> 0 : 0;
                        else if (type === 'string') {
                            if (encoding === 'base64') subject = base64clean(subject);
                            length = Buffer.byteLength(subject, encoding);
                        } else if (type === 'object' && subject !== null) {
                            // assume object is array-like
                            if (subject.type === 'Buffer' && isArray(subject.data)) subject = subject.data;
                            length = +subject.length > 0 ? Math.floor(+subject.length) : 0;
                        } else throw new TypeError('must start with number, buffer, array or string');

                        if (this.length > kMaxLength)
                            throw new RangeError(
                                'Attempt to allocate Buffer larger than maximum ' +
                                    'size: 0x' +
                                    kMaxLength.toString(16) +
                                    ' bytes',
                            );

                        var buf;
                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            // Preferred: Return an augmented `Uint8Array` instance for best performance
                            buf = Buffer._augment(new Uint8Array(length));
                        } else {
                            // Fallback: Return THIS instance of Buffer (created by `new`)
                            buf = this;
                            buf.length = length;
                            buf._isBuffer = true;
                        }

                        var i;
                        if (Buffer.TYPED_ARRAY_SUPPORT && typeof subject.byteLength === 'number') {
                            // Speed optimization -- use set if we're copying from a typed array
                            buf._set(subject);
                        } else if (isArrayish(subject)) {
                            // Treat array-ish objects as a byte array
                            if (Buffer.isBuffer(subject)) {
                                for (i = 0; i < length; i++) buf[i] = subject.readUInt8(i);
                            } else {
                                for (i = 0; i < length; i++) buf[i] = ((subject[i] % 256) + 256) % 256;
                            }
                        } else if (type === 'string') {
                            buf.write(subject, 0, encoding);
                        } else if (type === 'number' && !Buffer.TYPED_ARRAY_SUPPORT && !noZero) {
                            for (i = 0; i < length; i++) {
                                buf[i] = 0;
                            }
                        }

                        return buf;
                    }

                    Buffer.isBuffer = function (b) {
                        return !!(b != null && b._isBuffer);
                    };

                    Buffer.compare = function (a, b) {
                        if (!Buffer.isBuffer(a) || !Buffer.isBuffer(b))
                            throw new TypeError('Arguments must be Buffers');

                        var x = a.length;
                        var y = b.length;
                        for (var i = 0, len = Math.min(x, y); i < len && a[i] === b[i]; i++) {}
                        if (i !== len) {
                            x = a[i];
                            y = b[i];
                        }
                        if (x < y) return -1;
                        if (y < x) return 1;
                        return 0;
                    };

                    Buffer.isEncoding = function (encoding) {
                        switch (String(encoding).toLowerCase()) {
                            case 'hex':
                            case 'utf8':
                            case 'utf-8':
                            case 'ascii':
                            case 'binary':
                            case 'base64':
                            case 'raw':
                            case 'ucs2':
                            case 'ucs-2':
                            case 'utf16le':
                            case 'utf-16le':
                                return true;
                            default:
                                return false;
                        }
                    };

                    Buffer.concat = function (list, totalLength) {
                        if (!isArray(list)) throw new TypeError('Usage: Buffer.concat(list[, length])');

                        if (list.length === 0) {
                            return new Buffer(0);
                        } else if (list.length === 1) {
                            return list[0];
                        }

                        var i;
                        if (totalLength === undefined) {
                            totalLength = 0;
                            for (i = 0; i < list.length; i++) {
                                totalLength += list[i].length;
                            }
                        }

                        var buf = new Buffer(totalLength);
                        var pos = 0;
                        for (i = 0; i < list.length; i++) {
                            var item = list[i];
                            item.copy(buf, pos);
                            pos += item.length;
                        }
                        return buf;
                    };

                    Buffer.byteLength = function (str, encoding) {
                        var ret;
                        str = str + '';
                        switch (encoding || 'utf8') {
                            case 'ascii':
                            case 'binary':
                            case 'raw':
                                ret = str.length;
                                break;
                            case 'ucs2':
                            case 'ucs-2':
                            case 'utf16le':
                            case 'utf-16le':
                                ret = str.length * 2;
                                break;
                            case 'hex':
                                ret = str.length >>> 1;
                                break;
                            case 'utf8':
                            case 'utf-8':
                                ret = utf8ToBytes(str).length;
                                break;
                            case 'base64':
                                ret = base64ToBytes(str).length;
                                break;
                            default:
                                ret = str.length;
                        }
                        return ret;
                    };

                    // pre-set for values that may exist in the future
                    Buffer.prototype.length = undefined;
                    Buffer.prototype.parent = undefined;

                    // toString(encoding, start=0, end=buffer.length)
                    Buffer.prototype.toString = function (encoding, start, end) {
                        var loweredCase = false;

                        start = start >>> 0;
                        end = end === undefined || end === Infinity ? this.length : end >>> 0;

                        if (!encoding) encoding = 'utf8';
                        if (start < 0) start = 0;
                        if (end > this.length) end = this.length;
                        if (end <= start) return '';

                        while (true) {
                            switch (encoding) {
                                case 'hex':
                                    return hexSlice(this, start, end);

                                case 'utf8':
                                case 'utf-8':
                                    return utf8Slice(this, start, end);

                                case 'ascii':
                                    return asciiSlice(this, start, end);

                                case 'binary':
                                    return binarySlice(this, start, end);

                                case 'base64':
                                    return base64Slice(this, start, end);

                                case 'ucs2':
                                case 'ucs-2':
                                case 'utf16le':
                                case 'utf-16le':
                                    return utf16leSlice(this, start, end);

                                default:
                                    if (loweredCase) throw new TypeError('Unknown encoding: ' + encoding);
                                    encoding = (encoding + '').toLowerCase();
                                    loweredCase = true;
                            }
                        }
                    };

                    Buffer.prototype.equals = function (b) {
                        if (!Buffer.isBuffer(b)) throw new TypeError('Argument must be a Buffer');
                        return Buffer.compare(this, b) === 0;
                    };

                    Buffer.prototype.inspect = function () {
                        var str = '';
                        var max = exports.INSPECT_MAX_BYTES;
                        if (this.length > 0) {
                            str = this.toString('hex', 0, max).match(/.{2}/g).join(' ');
                            if (this.length > max) str += ' ... ';
                        }
                        return '<Buffer ' + str + '>';
                    };

                    Buffer.prototype.compare = function (b) {
                        if (!Buffer.isBuffer(b)) throw new TypeError('Argument must be a Buffer');
                        return Buffer.compare(this, b);
                    };

                    // `get` will be removed in Node 0.13+
                    Buffer.prototype.get = function (offset) {
                        console.log('.get() is deprecated. Access using array indexes instead.');
                        return this.readUInt8(offset);
                    };

                    // `set` will be removed in Node 0.13+
                    Buffer.prototype.set = function (v, offset) {
                        console.log('.set() is deprecated. Access using array indexes instead.');
                        return this.writeUInt8(v, offset);
                    };

                    function hexWrite(buf, string, offset, length) {
                        offset = Number(offset) || 0;
                        var remaining = buf.length - offset;
                        if (!length) {
                            length = remaining;
                        } else {
                            length = Number(length);
                            if (length > remaining) {
                                length = remaining;
                            }
                        }

                        // must be an even number of digits
                        var strLen = string.length;
                        if (strLen % 2 !== 0) throw new Error('Invalid hex string');

                        if (length > strLen / 2) {
                            length = strLen / 2;
                        }
                        for (var i = 0; i < length; i++) {
                            var byte = parseInt(string.substr(i * 2, 2), 16);
                            if (isNaN(byte)) throw new Error('Invalid hex string');
                            buf[offset + i] = byte;
                        }
                        return i;
                    }

                    function utf8Write(buf, string, offset, length) {
                        var charsWritten = blitBuffer(utf8ToBytes(string), buf, offset, length);
                        return charsWritten;
                    }

                    function asciiWrite(buf, string, offset, length) {
                        var charsWritten = blitBuffer(asciiToBytes(string), buf, offset, length);
                        return charsWritten;
                    }

                    function binaryWrite(buf, string, offset, length) {
                        return asciiWrite(buf, string, offset, length);
                    }

                    function base64Write(buf, string, offset, length) {
                        var charsWritten = blitBuffer(base64ToBytes(string), buf, offset, length);
                        return charsWritten;
                    }

                    function utf16leWrite(buf, string, offset, length) {
                        var charsWritten = blitBuffer(utf16leToBytes(string), buf, offset, length);
                        return charsWritten;
                    }

                    Buffer.prototype.write = function (string, offset, length, encoding) {
                        // Support both (string, offset, length, encoding)
                        // and the legacy (string, encoding, offset, length)
                        if (isFinite(offset)) {
                            if (!isFinite(length)) {
                                encoding = length;
                                length = undefined;
                            }
                        } else {
                            // legacy
                            var swap = encoding;
                            encoding = offset;
                            offset = length;
                            length = swap;
                        }

                        offset = Number(offset) || 0;
                        var remaining = this.length - offset;
                        if (!length) {
                            length = remaining;
                        } else {
                            length = Number(length);
                            if (length > remaining) {
                                length = remaining;
                            }
                        }
                        encoding = String(encoding || 'utf8').toLowerCase();

                        var ret;
                        switch (encoding) {
                            case 'hex':
                                ret = hexWrite(this, string, offset, length);
                                break;
                            case 'utf8':
                            case 'utf-8':
                                ret = utf8Write(this, string, offset, length);
                                break;
                            case 'ascii':
                                ret = asciiWrite(this, string, offset, length);
                                break;
                            case 'binary':
                                ret = binaryWrite(this, string, offset, length);
                                break;
                            case 'base64':
                                ret = base64Write(this, string, offset, length);
                                break;
                            case 'ucs2':
                            case 'ucs-2':
                            case 'utf16le':
                            case 'utf-16le':
                                ret = utf16leWrite(this, string, offset, length);
                                break;
                            default:
                                throw new TypeError('Unknown encoding: ' + encoding);
                        }
                        return ret;
                    };

                    Buffer.prototype.toJSON = function () {
                        return {
                            type: 'Buffer',
                            data: Array.prototype.slice.call(this._arr || this, 0),
                        };
                    };

                    function base64Slice(buf, start, end) {
                        if (start === 0 && end === buf.length) {
                            return base64.fromByteArray(buf);
                        } else {
                            return base64.fromByteArray(buf.slice(start, end));
                        }
                    }

                    function utf8Slice(buf, start, end) {
                        var res = '';
                        var tmp = '';
                        end = Math.min(buf.length, end);

                        for (var i = start; i < end; i++) {
                            if (buf[i] <= 0x7f) {
                                res += decodeUtf8Char(tmp) + String.fromCharCode(buf[i]);
                                tmp = '';
                            } else {
                                tmp += '%' + buf[i].toString(16);
                            }
                        }

                        return res + decodeUtf8Char(tmp);
                    }

                    function asciiSlice(buf, start, end) {
                        var ret = '';
                        end = Math.min(buf.length, end);

                        for (var i = start; i < end; i++) {
                            ret += String.fromCharCode(buf[i]);
                        }
                        return ret;
                    }

                    function binarySlice(buf, start, end) {
                        return asciiSlice(buf, start, end);
                    }

                    function hexSlice(buf, start, end) {
                        var len = buf.length;

                        if (!start || start < 0) start = 0;
                        if (!end || end < 0 || end > len) end = len;

                        var out = '';
                        for (var i = start; i < end; i++) {
                            out += toHex(buf[i]);
                        }
                        return out;
                    }

                    function utf16leSlice(buf, start, end) {
                        var bytes = buf.slice(start, end);
                        var res = '';
                        for (var i = 0; i < bytes.length; i += 2) {
                            res += String.fromCharCode(bytes[i] + bytes[i + 1] * 256);
                        }
                        return res;
                    }

                    Buffer.prototype.slice = function (start, end) {
                        var len = this.length;
                        start = ~~start;
                        end = end === undefined ? len : ~~end;

                        if (start < 0) {
                            start += len;
                            if (start < 0) start = 0;
                        } else if (start > len) {
                            start = len;
                        }

                        if (end < 0) {
                            end += len;
                            if (end < 0) end = 0;
                        } else if (end > len) {
                            end = len;
                        }

                        if (end < start) end = start;

                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            return Buffer._augment(this.subarray(start, end));
                        } else {
                            var sliceLen = end - start;
                            var newBuf = new Buffer(sliceLen, undefined, true);
                            for (var i = 0; i < sliceLen; i++) {
                                newBuf[i] = this[i + start];
                            }
                            return newBuf;
                        }
                    };

                    /*
                     * Need to make sure that buffer isn't trying to write out of bounds.
                     */
                    function checkOffset(offset, ext, length) {
                        if (offset % 1 !== 0 || offset < 0) throw new RangeError('offset is not uint');
                        if (offset + ext > length) throw new RangeError('Trying to access beyond buffer length');
                    }

                    Buffer.prototype.readUInt8 = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 1, this.length);
                        return this[offset];
                    };

                    Buffer.prototype.readUInt16LE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 2, this.length);
                        return this[offset] | (this[offset + 1] << 8);
                    };

                    Buffer.prototype.readUInt16BE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 2, this.length);
                        return (this[offset] << 8) | this[offset + 1];
                    };

                    Buffer.prototype.readUInt32LE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 4, this.length);

                        return (
                            (this[offset] | (this[offset + 1] << 8) | (this[offset + 2] << 16)) +
                            this[offset + 3] * 0x1000000
                        );
                    };

                    Buffer.prototype.readUInt32BE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 4, this.length);

                        return (
                            this[offset] * 0x1000000 +
                            ((this[offset + 1] << 16) | (this[offset + 2] << 8) | this[offset + 3])
                        );
                    };

                    Buffer.prototype.readInt8 = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 1, this.length);
                        if (!(this[offset] & 0x80)) return this[offset];
                        return (0xff - this[offset] + 1) * -1;
                    };

                    Buffer.prototype.readInt16LE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 2, this.length);
                        var val = this[offset] | (this[offset + 1] << 8);
                        return val & 0x8000 ? val | 0xffff0000 : val;
                    };

                    Buffer.prototype.readInt16BE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 2, this.length);
                        var val = this[offset + 1] | (this[offset] << 8);
                        return val & 0x8000 ? val | 0xffff0000 : val;
                    };

                    Buffer.prototype.readInt32LE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 4, this.length);

                        return (
                            this[offset] | (this[offset + 1] << 8) | (this[offset + 2] << 16) | (this[offset + 3] << 24)
                        );
                    };

                    Buffer.prototype.readInt32BE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 4, this.length);

                        return (
                            (this[offset] << 24) | (this[offset + 1] << 16) | (this[offset + 2] << 8) | this[offset + 3]
                        );
                    };

                    Buffer.prototype.readFloatLE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 4, this.length);
                        return ieee754.read(this, offset, true, 23, 4);
                    };

                    Buffer.prototype.readFloatBE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 4, this.length);
                        return ieee754.read(this, offset, false, 23, 4);
                    };

                    Buffer.prototype.readDoubleLE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 8, this.length);
                        return ieee754.read(this, offset, true, 52, 8);
                    };

                    Buffer.prototype.readDoubleBE = function (offset, noAssert) {
                        if (!noAssert) checkOffset(offset, 8, this.length);
                        return ieee754.read(this, offset, false, 52, 8);
                    };

                    function checkInt(buf, value, offset, ext, max, min) {
                        if (!Buffer.isBuffer(buf)) throw new TypeError('buffer must be a Buffer instance');
                        if (value > max || value < min) throw new TypeError('value is out of bounds');
                        if (offset + ext > buf.length) throw new TypeError('index out of range');
                    }

                    Buffer.prototype.writeUInt8 = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 1, 0xff, 0);
                        if (!Buffer.TYPED_ARRAY_SUPPORT) value = Math.floor(value);
                        this[offset] = value;
                        return offset + 1;
                    };

                    function objectWriteUInt16(buf, value, offset, littleEndian) {
                        if (value < 0) value = 0xffff + value + 1;
                        for (var i = 0, j = Math.min(buf.length - offset, 2); i < j; i++) {
                            buf[offset + i] =
                                (value & (0xff << (8 * (littleEndian ? i : 1 - i)))) >>>
                                ((littleEndian ? i : 1 - i) * 8);
                        }
                    }

                    Buffer.prototype.writeUInt16LE = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 2, 0xffff, 0);
                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            this[offset] = value;
                            this[offset + 1] = value >>> 8;
                        } else objectWriteUInt16(this, value, offset, true);
                        return offset + 2;
                    };

                    Buffer.prototype.writeUInt16BE = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 2, 0xffff, 0);
                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            this[offset] = value >>> 8;
                            this[offset + 1] = value;
                        } else objectWriteUInt16(this, value, offset, false);
                        return offset + 2;
                    };

                    function objectWriteUInt32(buf, value, offset, littleEndian) {
                        if (value < 0) value = 0xffffffff + value + 1;
                        for (var i = 0, j = Math.min(buf.length - offset, 4); i < j; i++) {
                            buf[offset + i] = (value >>> ((littleEndian ? i : 3 - i) * 8)) & 0xff;
                        }
                    }

                    Buffer.prototype.writeUInt32LE = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 4, 0xffffffff, 0);
                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            this[offset + 3] = value >>> 24;
                            this[offset + 2] = value >>> 16;
                            this[offset + 1] = value >>> 8;
                            this[offset] = value;
                        } else objectWriteUInt32(this, value, offset, true);
                        return offset + 4;
                    };

                    Buffer.prototype.writeUInt32BE = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 4, 0xffffffff, 0);
                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            this[offset] = value >>> 24;
                            this[offset + 1] = value >>> 16;
                            this[offset + 2] = value >>> 8;
                            this[offset + 3] = value;
                        } else objectWriteUInt32(this, value, offset, false);
                        return offset + 4;
                    };

                    Buffer.prototype.writeInt8 = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 1, 0x7f, -0x80);
                        if (!Buffer.TYPED_ARRAY_SUPPORT) value = Math.floor(value);
                        if (value < 0) value = 0xff + value + 1;
                        this[offset] = value;
                        return offset + 1;
                    };

                    Buffer.prototype.writeInt16LE = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 2, 0x7fff, -0x8000);
                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            this[offset] = value;
                            this[offset + 1] = value >>> 8;
                        } else objectWriteUInt16(this, value, offset, true);
                        return offset + 2;
                    };

                    Buffer.prototype.writeInt16BE = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 2, 0x7fff, -0x8000);
                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            this[offset] = value >>> 8;
                            this[offset + 1] = value;
                        } else objectWriteUInt16(this, value, offset, false);
                        return offset + 2;
                    };

                    Buffer.prototype.writeInt32LE = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 4, 0x7fffffff, -0x80000000);
                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            this[offset] = value;
                            this[offset + 1] = value >>> 8;
                            this[offset + 2] = value >>> 16;
                            this[offset + 3] = value >>> 24;
                        } else objectWriteUInt32(this, value, offset, true);
                        return offset + 4;
                    };

                    Buffer.prototype.writeInt32BE = function (value, offset, noAssert) {
                        value = +value;
                        offset = offset >>> 0;
                        if (!noAssert) checkInt(this, value, offset, 4, 0x7fffffff, -0x80000000);
                        if (value < 0) value = 0xffffffff + value + 1;
                        if (Buffer.TYPED_ARRAY_SUPPORT) {
                            this[offset] = value >>> 24;
                            this[offset + 1] = value >>> 16;
                            this[offset + 2] = value >>> 8;
                            this[offset + 3] = value;
                        } else objectWriteUInt32(this, value, offset, false);
                        return offset + 4;
                    };

                    function checkIEEE754(buf, value, offset, ext, max, min) {
                        if (value > max || value < min) throw new TypeError('value is out of bounds');
                        if (offset + ext > buf.length) throw new TypeError('index out of range');
                    }

                    function writeFloat(buf, value, offset, littleEndian, noAssert) {
                        if (!noAssert)
                            checkIEEE754(buf, value, offset, 4, 3.4028234663852886e38, -3.4028234663852886e38);
                        ieee754.write(buf, value, offset, littleEndian, 23, 4);
                        return offset + 4;
                    }

                    Buffer.prototype.writeFloatLE = function (value, offset, noAssert) {
                        return writeFloat(this, value, offset, true, noAssert);
                    };

                    Buffer.prototype.writeFloatBE = function (value, offset, noAssert) {
                        return writeFloat(this, value, offset, false, noAssert);
                    };

                    function writeDouble(buf, value, offset, littleEndian, noAssert) {
                        if (!noAssert)
                            checkIEEE754(buf, value, offset, 8, 1.7976931348623157e308, -1.7976931348623157e308);
                        ieee754.write(buf, value, offset, littleEndian, 52, 8);
                        return offset + 8;
                    }

                    Buffer.prototype.writeDoubleLE = function (value, offset, noAssert) {
                        return writeDouble(this, value, offset, true, noAssert);
                    };

                    Buffer.prototype.writeDoubleBE = function (value, offset, noAssert) {
                        return writeDouble(this, value, offset, false, noAssert);
                    };

                    // copy(targetBuffer, targetStart=0, sourceStart=0, sourceEnd=buffer.length)
                    Buffer.prototype.copy = function (target, target_start, start, end) {
                        var source = this;

                        if (!start) start = 0;
                        if (!end && end !== 0) end = this.length;
                        if (!target_start) target_start = 0;

                        // Copy 0 bytes; we're done
                        if (end === start) return;
                        if (target.length === 0 || source.length === 0) return;

                        // Fatal error conditions
                        if (end < start) throw new TypeError('sourceEnd < sourceStart');
                        if (target_start < 0 || target_start >= target.length)
                            throw new TypeError('targetStart out of bounds');
                        if (start < 0 || start >= source.length) throw new TypeError('sourceStart out of bounds');
                        if (end < 0 || end > source.length) throw new TypeError('sourceEnd out of bounds');

                        // Are we oob?
                        if (end > this.length) end = this.length;
                        if (target.length - target_start < end - start) end = target.length - target_start + start;

                        var len = end - start;

                        if (len < 1000 || !Buffer.TYPED_ARRAY_SUPPORT) {
                            for (var i = 0; i < len; i++) {
                                target[i + target_start] = this[i + start];
                            }
                        } else {
                            target._set(this.subarray(start, start + len), target_start);
                        }
                    };

                    // fill(value, start=0, end=buffer.length)
                    Buffer.prototype.fill = function (value, start, end) {
                        if (!value) value = 0;
                        if (!start) start = 0;
                        if (!end) end = this.length;

                        if (end < start) throw new TypeError('end < start');

                        // Fill 0 bytes; we're done
                        if (end === start) return;
                        if (this.length === 0) return;

                        if (start < 0 || start >= this.length) throw new TypeError('start out of bounds');
                        if (end < 0 || end > this.length) throw new TypeError('end out of bounds');

                        var i;
                        if (typeof value === 'number') {
                            for (i = start; i < end; i++) {
                                this[i] = value;
                            }
                        } else {
                            var bytes = utf8ToBytes(value.toString());
                            var len = bytes.length;
                            for (i = start; i < end; i++) {
                                this[i] = bytes[i % len];
                            }
                        }

                        return this;
                    };

                    /**
                     * Creates a new `ArrayBuffer` with the *copied* memory of the buffer instance.
                     * Added in Node 0.12. Only available in browsers that support ArrayBuffer.
                     */
                    Buffer.prototype.toArrayBuffer = function () {
                        if (typeof Uint8Array !== 'undefined') {
                            if (Buffer.TYPED_ARRAY_SUPPORT) {
                                return new Buffer(this).buffer;
                            } else {
                                var buf = new Uint8Array(this.length);
                                for (var i = 0, len = buf.length; i < len; i += 1) {
                                    buf[i] = this[i];
                                }
                                return buf.buffer;
                            }
                        } else {
                            throw new TypeError('Buffer.toArrayBuffer not supported in this browser');
                        }
                    };

                    // HELPER FUNCTIONS
                    // ================

                    var BP = Buffer.prototype;

                    /**
                     * Augment a Uint8Array *instance* (not the Uint8Array class!) with Buffer methods
                     */
                    Buffer._augment = function (arr) {
                        arr.constructor = Buffer;
                        arr._isBuffer = true;

                        // save reference to original Uint8Array get/set methods before overwriting
                        arr._get = arr.get;
                        arr._set = arr.set;

                        // deprecated, will be removed in node 0.13+
                        arr.get = BP.get;
                        arr.set = BP.set;

                        arr.write = BP.write;
                        arr.toString = BP.toString;
                        arr.toLocaleString = BP.toString;
                        arr.toJSON = BP.toJSON;
                        arr.equals = BP.equals;
                        arr.compare = BP.compare;
                        arr.copy = BP.copy;
                        arr.slice = BP.slice;
                        arr.readUInt8 = BP.readUInt8;
                        arr.readUInt16LE = BP.readUInt16LE;
                        arr.readUInt16BE = BP.readUInt16BE;
                        arr.readUInt32LE = BP.readUInt32LE;
                        arr.readUInt32BE = BP.readUInt32BE;
                        arr.readInt8 = BP.readInt8;
                        arr.readInt16LE = BP.readInt16LE;
                        arr.readInt16BE = BP.readInt16BE;
                        arr.readInt32LE = BP.readInt32LE;
                        arr.readInt32BE = BP.readInt32BE;
                        arr.readFloatLE = BP.readFloatLE;
                        arr.readFloatBE = BP.readFloatBE;
                        arr.readDoubleLE = BP.readDoubleLE;
                        arr.readDoubleBE = BP.readDoubleBE;
                        arr.writeUInt8 = BP.writeUInt8;
                        arr.writeUInt16LE = BP.writeUInt16LE;
                        arr.writeUInt16BE = BP.writeUInt16BE;
                        arr.writeUInt32LE = BP.writeUInt32LE;
                        arr.writeUInt32BE = BP.writeUInt32BE;
                        arr.writeInt8 = BP.writeInt8;
                        arr.writeInt16LE = BP.writeInt16LE;
                        arr.writeInt16BE = BP.writeInt16BE;
                        arr.writeInt32LE = BP.writeInt32LE;
                        arr.writeInt32BE = BP.writeInt32BE;
                        arr.writeFloatLE = BP.writeFloatLE;
                        arr.writeFloatBE = BP.writeFloatBE;
                        arr.writeDoubleLE = BP.writeDoubleLE;
                        arr.writeDoubleBE = BP.writeDoubleBE;
                        arr.fill = BP.fill;
                        arr.inspect = BP.inspect;
                        arr.toArrayBuffer = BP.toArrayBuffer;

                        return arr;
                    };

                    var INVALID_BASE64_RE = /[^+\/0-9A-z]/g;

                    function base64clean(str) {
                        // Node strips out invalid characters like \n and \t from the string, base64-js does not
                        str = stringtrim(str).replace(INVALID_BASE64_RE, '');
                        // Node allows for non-padded base64 strings (missing trailing ===), base64-js does not
                        while (str.length % 4 !== 0) {
                            str = str + '=';
                        }
                        return str;
                    }

                    function stringtrim(str) {
                        if (str.trim) return str.trim();
                        return str.replace(/^\s+|\s+$/g, '');
                    }

                    function isArrayish(subject) {
                        return (
                            isArray(subject) ||
                            Buffer.isBuffer(subject) ||
                            (subject && typeof subject === 'object' && typeof subject.length === 'number')
                        );
                    }

                    function toHex(n) {
                        if (n < 16) return '0' + n.toString(16);
                        return n.toString(16);
                    }

                    function utf8ToBytes(str) {
                        var byteArray = [];
                        for (var i = 0; i < str.length; i++) {
                            var b = str.charCodeAt(i);
                            if (b <= 0x7f) {
                                byteArray.push(b);
                            } else {
                                var start = i;
                                if (b >= 0xd800 && b <= 0xdfff) i++;
                                var h = encodeURIComponent(str.slice(start, i + 1))
                                    .substr(1)
                                    .split('%');
                                for (var j = 0; j < h.length; j++) {
                                    byteArray.push(parseInt(h[j], 16));
                                }
                            }
                        }
                        return byteArray;
                    }

                    function asciiToBytes(str) {
                        var byteArray = [];
                        for (var i = 0; i < str.length; i++) {
                            // Node's code seems to be doing this and not & 0x7F..
                            byteArray.push(str.charCodeAt(i) & 0xff);
                        }
                        return byteArray;
                    }

                    function utf16leToBytes(str) {
                        var c, hi, lo;
                        var byteArray = [];
                        for (var i = 0; i < str.length; i++) {
                            c = str.charCodeAt(i);
                            hi = c >> 8;
                            lo = c % 256;
                            byteArray.push(lo);
                            byteArray.push(hi);
                        }

                        return byteArray;
                    }

                    function base64ToBytes(str) {
                        return base64.toByteArray(str);
                    }

                    function blitBuffer(src, dst, offset, length) {
                        for (var i = 0; i < length; i++) {
                            if (i + offset >= dst.length || i >= src.length) break;
                            dst[i + offset] = src[i];
                        }
                        return i;
                    }

                    function decodeUtf8Char(str) {
                        try {
                            return decodeURIComponent(str);
                        } catch (err) {
                            return String.fromCharCode(0xfffd); // UTF 8 invalid char
                        }
                    }
                },
                { 'base64-js': 4, ieee754: 5, 'is-array': 6 },
            ],
            4: [
                function (require, module, exports) {
                    var lookup = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

                    (function (exports) {
                        'use strict';

                        var Arr = typeof Uint8Array !== 'undefined' ? Uint8Array : Array;

                        var PLUS = '+'.charCodeAt(0);
                        var SLASH = '/'.charCodeAt(0);
                        var NUMBER = '0'.charCodeAt(0);
                        var LOWER = 'a'.charCodeAt(0);
                        var UPPER = 'A'.charCodeAt(0);

                        function decode(elt) {
                            var code = elt.charCodeAt(0);
                            if (code === PLUS) return 62; // '+'
                            if (code === SLASH) return 63; // '/'
                            if (code < NUMBER) return -1; //no match
                            if (code < NUMBER + 10) return code - NUMBER + 26 + 26;
                            if (code < UPPER + 26) return code - UPPER;
                            if (code < LOWER + 26) return code - LOWER + 26;
                        }

                        function b64ToByteArray(b64) {
                            var i, j, l, tmp, placeHolders, arr;

                            if (b64.length % 4 > 0) {
                                throw new Error('Invalid string. Length must be a multiple of 4');
                            }

                            // the number of equal signs (place holders)
                            // if there are two placeholders, than the two characters before it
                            // represent one byte
                            // if there is only one, then the three characters before it represent 2 bytes
                            // this is just a cheap hack to not do indexOf twice
                            var len = b64.length;
                            placeHolders = '=' === b64.charAt(len - 2) ? 2 : '=' === b64.charAt(len - 1) ? 1 : 0;

                            // base64 is 4/3 + up to two characters of the original data
                            arr = new Arr((b64.length * 3) / 4 - placeHolders);

                            // if there are placeholders, only get up to the last complete 4 chars
                            l = placeHolders > 0 ? b64.length - 4 : b64.length;

                            var L = 0;

                            function push(v) {
                                arr[L++] = v;
                            }

                            for (i = 0, j = 0; i < l; i += 4, j += 3) {
                                tmp =
                                    (decode(b64.charAt(i)) << 18) |
                                    (decode(b64.charAt(i + 1)) << 12) |
                                    (decode(b64.charAt(i + 2)) << 6) |
                                    decode(b64.charAt(i + 3));
                                push((tmp & 0xff0000) >> 16);
                                push((tmp & 0xff00) >> 8);
                                push(tmp & 0xff);
                            }

                            if (placeHolders === 2) {
                                tmp = (decode(b64.charAt(i)) << 2) | (decode(b64.charAt(i + 1)) >> 4);
                                push(tmp & 0xff);
                            } else if (placeHolders === 1) {
                                tmp =
                                    (decode(b64.charAt(i)) << 10) |
                                    (decode(b64.charAt(i + 1)) << 4) |
                                    (decode(b64.charAt(i + 2)) >> 2);
                                push((tmp >> 8) & 0xff);
                                push(tmp & 0xff);
                            }

                            return arr;
                        }

                        function uint8ToBase64(uint8) {
                            var i,
                                extraBytes = uint8.length % 3, // if we have 1 byte left, pad 2 bytes
                                output = '',
                                temp,
                                length;

                            function encode(num) {
                                return lookup.charAt(num);
                            }

                            function tripletToBase64(num) {
                                return (
                                    encode((num >> 18) & 0x3f) +
                                    encode((num >> 12) & 0x3f) +
                                    encode((num >> 6) & 0x3f) +
                                    encode(num & 0x3f)
                                );
                            }

                            // go through the array every three bytes, we'll deal with trailing stuff later
                            for (i = 0, length = uint8.length - extraBytes; i < length; i += 3) {
                                temp = (uint8[i] << 16) + (uint8[i + 1] << 8) + uint8[i + 2];
                                output += tripletToBase64(temp);
                            }

                            // pad the end with zeros, but make sure to not forget the extra bytes
                            switch (extraBytes) {
                                case 1:
                                    temp = uint8[uint8.length - 1];
                                    output += encode(temp >> 2);
                                    output += encode((temp << 4) & 0x3f);
                                    output += '==';
                                    break;
                                case 2:
                                    temp = (uint8[uint8.length - 2] << 8) + uint8[uint8.length - 1];
                                    output += encode(temp >> 10);
                                    output += encode((temp >> 4) & 0x3f);
                                    output += encode((temp << 2) & 0x3f);
                                    output += '=';
                                    break;
                            }

                            return output;
                        }

                        exports.toByteArray = b64ToByteArray;
                        exports.fromByteArray = uint8ToBase64;
                    })(typeof exports === 'undefined' ? (this.base64js = {}) : exports);
                },
                {},
            ],
            5: [
                function (require, module, exports) {
                    exports.read = function (buffer, offset, isLE, mLen, nBytes) {
                        var e,
                            m,
                            eLen = nBytes * 8 - mLen - 1,
                            eMax = (1 << eLen) - 1,
                            eBias = eMax >> 1,
                            nBits = -7,
                            i = isLE ? nBytes - 1 : 0,
                            d = isLE ? -1 : 1,
                            s = buffer[offset + i];

                        i += d;

                        e = s & ((1 << -nBits) - 1);
                        s >>= -nBits;
                        nBits += eLen;
                        for (; nBits > 0; e = e * 256 + buffer[offset + i], i += d, nBits -= 8);

                        m = e & ((1 << -nBits) - 1);
                        e >>= -nBits;
                        nBits += mLen;
                        for (; nBits > 0; m = m * 256 + buffer[offset + i], i += d, nBits -= 8);

                        if (e === 0) {
                            e = 1 - eBias;
                        } else if (e === eMax) {
                            return m ? NaN : (s ? -1 : 1) * Infinity;
                        } else {
                            m = m + Math.pow(2, mLen);
                            e = e - eBias;
                        }
                        return (s ? -1 : 1) * m * Math.pow(2, e - mLen);
                    };

                    exports.write = function (buffer, value, offset, isLE, mLen, nBytes) {
                        var e,
                            m,
                            c,
                            eLen = nBytes * 8 - mLen - 1,
                            eMax = (1 << eLen) - 1,
                            eBias = eMax >> 1,
                            rt = mLen === 23 ? Math.pow(2, -24) - Math.pow(2, -77) : 0,
                            i = isLE ? 0 : nBytes - 1,
                            d = isLE ? 1 : -1,
                            s = value < 0 || (value === 0 && 1 / value < 0) ? 1 : 0;

                        value = Math.abs(value);

                        if (isNaN(value) || value === Infinity) {
                            m = isNaN(value) ? 1 : 0;
                            e = eMax;
                        } else {
                            e = Math.floor(Math.log(value) / Math.LN2);
                            if (value * (c = Math.pow(2, -e)) < 1) {
                                e--;
                                c *= 2;
                            }
                            if (e + eBias >= 1) {
                                value += rt / c;
                            } else {
                                value += rt * Math.pow(2, 1 - eBias);
                            }
                            if (value * c >= 2) {
                                e++;
                                c /= 2;
                            }

                            if (e + eBias >= eMax) {
                                m = 0;
                                e = eMax;
                            } else if (e + eBias >= 1) {
                                m = (value * c - 1) * Math.pow(2, mLen);
                                e = e + eBias;
                            } else {
                                m = value * Math.pow(2, eBias - 1) * Math.pow(2, mLen);
                                e = 0;
                            }
                        }

                        for (; mLen >= 8; buffer[offset + i] = m & 0xff, i += d, m /= 256, mLen -= 8);

                        e = (e << mLen) | m;
                        eLen += mLen;
                        for (; eLen > 0; buffer[offset + i] = e & 0xff, i += d, e /= 256, eLen -= 8);

                        buffer[offset + i - d] |= s * 128;
                    };
                },
                {},
            ],
            6: [
                function (require, module, exports) {
                    /**
                     * isArray
                     */

                    var isArray = Array.isArray;

                    /**
                     * toString
                     */

                    var str = Object.prototype.toString;

                    /**
                     * Whether or not the given `val`
                     * is an array.
                     *
                     * example:
                     *
                     *        isArray([]);
                     *        // > true
                     *        isArray(arguments);
                     *        // > false
                     *        isArray('');
                     *        // > false
                     *
                     * @param {mixed} val
                     * @return {bool}
                     */

                    module.exports =
                        isArray ||
                        function (val) {
                            return !!val && '[object Array]' == str.call(val);
                        };
                },
                {},
            ],
            7: [
                function (require, module, exports) {
                    // Copyright Joyent, Inc. and other Node contributors.
                    //
                    // Permission is hereby granted, free of charge, to any person obtaining a
                    // copy of this software and associated documentation files (the
                    // "Software"), to deal in the Software without restriction, including
                    // without limitation the rights to use, copy, modify, merge, publish,
                    // distribute, sublicense, and/or sell copies of the Software, and to permit
                    // persons to whom the Software is furnished to do so, subject to the
                    // following conditions:
                    //
                    // The above copyright notice and this permission notice shall be included
                    // in all copies or substantial portions of the Software.
                    //
                    // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                    // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                    // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                    // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                    // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                    // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                    // USE OR OTHER DEALINGS IN THE SOFTWARE.

                    function EventEmitter() {
                        this._events = this._events || {};
                        this._maxListeners = this._maxListeners || undefined;
                    }
                    module.exports = EventEmitter;

                    // Backwards-compat with node 0.10.x
                    EventEmitter.EventEmitter = EventEmitter;

                    EventEmitter.prototype._events = undefined;
                    EventEmitter.prototype._maxListeners = undefined;

                    // By default EventEmitters will print a warning if more than 10 listeners are
                    // added to it. This is a useful default which helps finding memory leaks.
                    EventEmitter.defaultMaxListeners = 10;

                    // Obviously not all Emitters should be limited to 10. This function allows
                    // that to be increased. Set to zero for unlimited.
                    EventEmitter.prototype.setMaxListeners = function (n) {
                        if (!isNumber(n) || n < 0 || isNaN(n)) throw TypeError('n must be a positive number');
                        this._maxListeners = n;
                        return this;
                    };

                    EventEmitter.prototype.emit = function (type) {
                        var er, handler, len, args, i, listeners;

                        if (!this._events) this._events = {};

                        // If there is no 'error' event listener then throw.
                        if (type === 'error') {
                            if (!this._events.error || (isObject(this._events.error) && !this._events.error.length)) {
                                er = arguments[1];
                                if (er instanceof Error) {
                                    throw er; // Unhandled 'error' event
                                }
                                throw TypeError('Uncaught, unspecified "error" event.');
                            }
                        }

                        handler = this._events[type];

                        if (isUndefined(handler)) return false;

                        if (isFunction(handler)) {
                            switch (arguments.length) {
                                // fast cases
                                case 1:
                                    handler.call(this);
                                    break;
                                case 2:
                                    handler.call(this, arguments[1]);
                                    break;
                                case 3:
                                    handler.call(this, arguments[1], arguments[2]);
                                    break;
                                // slower
                                default:
                                    len = arguments.length;
                                    args = new Array(len - 1);
                                    for (i = 1; i < len; i++) args[i - 1] = arguments[i];
                                    handler.apply(this, args);
                            }
                        } else if (isObject(handler)) {
                            len = arguments.length;
                            args = new Array(len - 1);
                            for (i = 1; i < len; i++) args[i - 1] = arguments[i];

                            listeners = handler.slice();
                            len = listeners.length;
                            for (i = 0; i < len; i++) listeners[i].apply(this, args);
                        }

                        return true;
                    };

                    EventEmitter.prototype.addListener = function (type, listener) {
                        var m;

                        if (!isFunction(listener)) throw TypeError('listener must be a function');

                        if (!this._events) this._events = {};

                        // To avoid recursion in the case that type === "newListener"! Before
                        // adding it to the listeners, first emit "newListener".
                        if (this._events.newListener)
                            this.emit(
                                'newListener',
                                type,
                                isFunction(listener.listener) ? listener.listener : listener,
                            );

                        if (!this._events[type])
                            // Optimize the case of one listener. Don't need the extra array object.
                            this._events[type] = listener;
                        else if (isObject(this._events[type]))
                            // If we've already got an array, just append.
                            this._events[type].push(listener);
                        // Adding the second element, need to change to array.
                        else this._events[type] = [this._events[type], listener];

                        // Check for listener leak
                        if (isObject(this._events[type]) && !this._events[type].warned) {
                            var m;
                            if (!isUndefined(this._maxListeners)) {
                                m = this._maxListeners;
                            } else {
                                m = EventEmitter.defaultMaxListeners;
                            }

                            if (m && m > 0 && this._events[type].length > m) {
                                this._events[type].warned = true;
                                console.error(
                                    '(node) warning: possible EventEmitter memory ' +
                                        'leak detected. %d listeners added. ' +
                                        'Use emitter.setMaxListeners() to increase limit.',
                                    this._events[type].length,
                                );
                                if (typeof console.trace === 'function') {
                                    // not supported in IE 10
                                    console.trace();
                                }
                            }
                        }

                        return this;
                    };

                    EventEmitter.prototype.on = EventEmitter.prototype.addListener;

                    EventEmitter.prototype.once = function (type, listener) {
                        if (!isFunction(listener)) throw TypeError('listener must be a function');

                        var fired = false;

                        function g() {
                            this.removeListener(type, g);

                            if (!fired) {
                                fired = true;
                                listener.apply(this, arguments);
                            }
                        }

                        g.listener = listener;
                        this.on(type, g);

                        return this;
                    };

                    // emits a 'removeListener' event iff the listener was removed
                    EventEmitter.prototype.removeListener = function (type, listener) {
                        var list, position, length, i;

                        if (!isFunction(listener)) throw TypeError('listener must be a function');

                        if (!this._events || !this._events[type]) return this;

                        list = this._events[type];
                        length = list.length;
                        position = -1;

                        if (list === listener || (isFunction(list.listener) && list.listener === listener)) {
                            delete this._events[type];
                            if (this._events.removeListener) this.emit('removeListener', type, listener);
                        } else if (isObject(list)) {
                            for (i = length; i-- > 0; ) {
                                if (list[i] === listener || (list[i].listener && list[i].listener === listener)) {
                                    position = i;
                                    break;
                                }
                            }

                            if (position < 0) return this;

                            if (list.length === 1) {
                                list.length = 0;
                                delete this._events[type];
                            } else {
                                list.splice(position, 1);
                            }

                            if (this._events.removeListener) this.emit('removeListener', type, listener);
                        }

                        return this;
                    };

                    EventEmitter.prototype.removeAllListeners = function (type) {
                        var key, listeners;

                        if (!this._events) return this;

                        // not listening for removeListener, no need to emit
                        if (!this._events.removeListener) {
                            if (arguments.length === 0) this._events = {};
                            else if (this._events[type]) delete this._events[type];
                            return this;
                        }

                        // emit removeListener for all listeners on all events
                        if (arguments.length === 0) {
                            for (key in this._events) {
                                if (key === 'removeListener') continue;
                                this.removeAllListeners(key);
                            }
                            this.removeAllListeners('removeListener');
                            this._events = {};
                            return this;
                        }

                        listeners = this._events[type];

                        if (isFunction(listeners)) {
                            this.removeListener(type, listeners);
                        } else {
                            // LIFO order
                            while (listeners.length) this.removeListener(type, listeners[listeners.length - 1]);
                        }
                        delete this._events[type];

                        return this;
                    };

                    EventEmitter.prototype.listeners = function (type) {
                        var ret;
                        if (!this._events || !this._events[type]) ret = [];
                        else if (isFunction(this._events[type])) ret = [this._events[type]];
                        else ret = this._events[type].slice();
                        return ret;
                    };

                    EventEmitter.listenerCount = function (emitter, type) {
                        var ret;
                        if (!emitter._events || !emitter._events[type]) ret = 0;
                        else if (isFunction(emitter._events[type])) ret = 1;
                        else ret = emitter._events[type].length;
                        return ret;
                    };

                    function isFunction(arg) {
                        return typeof arg === 'function';
                    }

                    function isNumber(arg) {
                        return typeof arg === 'number';
                    }

                    function isObject(arg) {
                        return typeof arg === 'object' && arg !== null;
                    }

                    function isUndefined(arg) {
                        return arg === void 0;
                    }
                },
                {},
            ],
            8: [
                function (require, module, exports) {
                    if (typeof Object.create === 'function') {
                        // implementation from standard node.js 'util' module
                        module.exports = function inherits(ctor, superCtor) {
                            ctor.super_ = superCtor;
                            ctor.prototype = Object.create(superCtor.prototype, {
                                constructor: {
                                    value: ctor,
                                    enumerable: false,
                                    writable: true,
                                    configurable: true,
                                },
                            });
                        };
                    } else {
                        // old school shim for old browsers
                        module.exports = function inherits(ctor, superCtor) {
                            ctor.super_ = superCtor;
                            var TempCtor = function () {};
                            TempCtor.prototype = superCtor.prototype;
                            ctor.prototype = new TempCtor();
                            ctor.prototype.constructor = ctor;
                        };
                    }
                },
                {},
            ],
            9: [
                function (require, module, exports) {
                    module.exports =
                        Array.isArray ||
                        function (arr) {
                            return Object.prototype.toString.call(arr) == '[object Array]';
                        };
                },
                {},
            ],
            10: [
                function (require, module, exports) {
                    // shim for using process in browser

                    var process = (module.exports = {});

                    process.nextTick = (function () {
                        var canSetImmediate = typeof window !== 'undefined' && window.setImmediate;
                        var canMutationObserver = typeof window !== 'undefined' && window.MutationObserver;
                        var canPost = typeof window !== 'undefined' && window.postMessage && window.addEventListener;
                        if (canSetImmediate) {
                            return function (f) {
                                return window.setImmediate(f);
                            };
                        }

                        var queue = [];

                        if (canMutationObserver) {
                            var hiddenDiv = document.createElement('div');
                            var observer = new MutationObserver(function () {
                                var queueList = queue.slice();
                                queue.length = 0;
                                queueList.forEach(function (fn) {
                                    fn();
                                });
                            });

                            observer.observe(hiddenDiv, { attributes: true });

                            return function nextTick(fn) {
                                if (!queue.length) {
                                    hiddenDiv.setAttribute('yes', 'no');
                                }
                                queue.push(fn);
                            };
                        }

                        if (canPost) {
                            window.addEventListener(
                                'message',
                                function (ev) {
                                    var source = ev.source;
                                    if ((source === window || source === null) && ev.data === 'process-tick') {
                                        ev.stopPropagation();
                                        if (queue.length > 0) {
                                            var fn = queue.shift();
                                            fn();
                                        }
                                    }
                                },
                                true,
                            );

                            return function nextTick(fn) {
                                queue.push(fn);
                                window.postMessage('process-tick', '*');
                            };
                        }

                        return function nextTick(fn) {
                            setTimeout(fn, 0);
                        };
                    })();

                    process.title = 'browser';
                    process.browser = true;
                    process.env = {};
                    process.argv = [];

                    function noop() {}

                    process.on = noop;
                    process.addListener = noop;
                    process.once = noop;
                    process.off = noop;
                    process.removeListener = noop;
                    process.removeAllListeners = noop;
                    process.emit = noop;

                    process.binding = function (name) {
                        throw new Error('process.binding is not supported');
                    };

                    // TODO(shtylman)
                    process.cwd = function () {
                        return '/';
                    };
                    process.chdir = function (dir) {
                        throw new Error('process.chdir is not supported');
                    };
                },
                {},
            ],
            11: [
                function (require, module, exports) {
                    module.exports = require('./lib/_stream_duplex.js');
                },
                { './lib/_stream_duplex.js': 12 },
            ],
            12: [
                function (require, module, exports) {
                    (function (process) {
                        // Copyright Joyent, Inc. and other Node contributors.
                        //
                        // Permission is hereby granted, free of charge, to any person obtaining a
                        // copy of this software and associated documentation files (the
                        // "Software"), to deal in the Software without restriction, including
                        // without limitation the rights to use, copy, modify, merge, publish,
                        // distribute, sublicense, and/or sell copies of the Software, and to permit
                        // persons to whom the Software is furnished to do so, subject to the
                        // following conditions:
                        //
                        // The above copyright notice and this permission notice shall be included
                        // in all copies or substantial portions of the Software.
                        //
                        // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                        // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                        // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                        // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                        // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                        // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                        // USE OR OTHER DEALINGS IN THE SOFTWARE.

                        // a duplex stream is just a stream that is both readable and writable.
                        // Since JS doesn't have multiple prototypal inheritance, this class
                        // prototypally inherits from Readable, and then parasitically from
                        // Writable.

                        module.exports = Duplex;

                        /*<replacement>*/
                        var objectKeys =
                            Object.keys ||
                            function (obj) {
                                var keys = [];
                                for (var key in obj) keys.push(key);
                                return keys;
                            };
                        /*</replacement>*/

                        /*<replacement>*/
                        var util = require('core-util-is');
                        util.inherits = require('inherits');
                        /*</replacement>*/

                        var Readable = require('./_stream_readable');
                        var Writable = require('./_stream_writable');

                        util.inherits(Duplex, Readable);

                        forEach(objectKeys(Writable.prototype), function (method) {
                            if (!Duplex.prototype[method]) Duplex.prototype[method] = Writable.prototype[method];
                        });

                        function Duplex(options) {
                            if (!(this instanceof Duplex)) return new Duplex(options);

                            Readable.call(this, options);
                            Writable.call(this, options);

                            if (options && options.readable === false) this.readable = false;

                            if (options && options.writable === false) this.writable = false;

                            this.allowHalfOpen = true;
                            if (options && options.allowHalfOpen === false) this.allowHalfOpen = false;

                            this.once('end', onend);
                        }

                        // the no-half-open enforcer
                        function onend() {
                            // if we allow half-open state, or if the writable side ended,
                            // then we're ok.
                            if (this.allowHalfOpen || this._writableState.ended) return;

                            // no more data can be written.
                            // But allow more writes to happen in this tick.
                            process.nextTick(this.end.bind(this));
                        }

                        function forEach(xs, f) {
                            for (var i = 0, l = xs.length; i < l; i++) {
                                f(xs[i], i);
                            }
                        }
                    }.call(this, require('_process')));
                },
                { './_stream_readable': 14, './_stream_writable': 16, _process: 10, 'core-util-is': 17, inherits: 8 },
            ],
            13: [
                function (require, module, exports) {
                    // Copyright Joyent, Inc. and other Node contributors.
                    //
                    // Permission is hereby granted, free of charge, to any person obtaining a
                    // copy of this software and associated documentation files (the
                    // "Software"), to deal in the Software without restriction, including
                    // without limitation the rights to use, copy, modify, merge, publish,
                    // distribute, sublicense, and/or sell copies of the Software, and to permit
                    // persons to whom the Software is furnished to do so, subject to the
                    // following conditions:
                    //
                    // The above copyright notice and this permission notice shall be included
                    // in all copies or substantial portions of the Software.
                    //
                    // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                    // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                    // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                    // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                    // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                    // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                    // USE OR OTHER DEALINGS IN THE SOFTWARE.

                    // a passthrough stream.
                    // basically just the most minimal sort of Transform stream.
                    // Every written chunk gets output as-is.

                    module.exports = PassThrough;

                    var Transform = require('./_stream_transform');

                    /*<replacement>*/
                    var util = require('core-util-is');
                    util.inherits = require('inherits');
                    /*</replacement>*/

                    util.inherits(PassThrough, Transform);

                    function PassThrough(options) {
                        if (!(this instanceof PassThrough)) return new PassThrough(options);

                        Transform.call(this, options);
                    }

                    PassThrough.prototype._transform = function (chunk, encoding, cb) {
                        cb(null, chunk);
                    };
                },
                { './_stream_transform': 15, 'core-util-is': 17, inherits: 8 },
            ],
            14: [
                function (require, module, exports) {
                    (function (process) {
                        // Copyright Joyent, Inc. and other Node contributors.
                        //
                        // Permission is hereby granted, free of charge, to any person obtaining a
                        // copy of this software and associated documentation files (the
                        // "Software"), to deal in the Software without restriction, including
                        // without limitation the rights to use, copy, modify, merge, publish,
                        // distribute, sublicense, and/or sell copies of the Software, and to permit
                        // persons to whom the Software is furnished to do so, subject to the
                        // following conditions:
                        //
                        // The above copyright notice and this permission notice shall be included
                        // in all copies or substantial portions of the Software.
                        //
                        // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                        // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                        // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                        // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                        // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                        // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                        // USE OR OTHER DEALINGS IN THE SOFTWARE.

                        module.exports = Readable;

                        /*<replacement>*/
                        var isArray = require('isarray');
                        /*</replacement>*/

                        /*<replacement>*/
                        var Buffer = require('buffer').Buffer;
                        /*</replacement>*/

                        Readable.ReadableState = ReadableState;

                        var EE = require('events').EventEmitter;

                        /*<replacement>*/
                        if (!EE.listenerCount)
                            EE.listenerCount = function (emitter, type) {
                                return emitter.listeners(type).length;
                            };
                        /*</replacement>*/

                        var Stream = require('stream');

                        /*<replacement>*/
                        var util = require('core-util-is');
                        util.inherits = require('inherits');
                        /*</replacement>*/

                        var StringDecoder;

                        util.inherits(Readable, Stream);

                        function ReadableState(options, stream) {
                            options = options || {};

                            // the point at which it stops calling _read() to fill the buffer
                            // Note: 0 is a valid value, means "don't call _read preemptively ever"
                            var hwm = options.highWaterMark;
                            this.highWaterMark = hwm || hwm === 0 ? hwm : 16 * 1024;

                            // cast to ints.
                            this.highWaterMark = ~~this.highWaterMark;

                            this.buffer = [];
                            this.length = 0;
                            this.pipes = null;
                            this.pipesCount = 0;
                            this.flowing = false;
                            this.ended = false;
                            this.endEmitted = false;
                            this.reading = false;

                            // In streams that never have any data, and do push(null) right away,
                            // the consumer can miss the 'end' event if they do some I/O before
                            // consuming the stream.  So, we don't emit('end') until some reading
                            // happens.
                            this.calledRead = false;

                            // a flag to be able to tell if the onwrite cb is called immediately,
                            // or on a later tick.  We set this to true at first, becuase any
                            // actions that shouldn't happen until "later" should generally also
                            // not happen before the first write call.
                            this.sync = true;

                            // whenever we return null, then we set a flag to say
                            // that we're awaiting a 'readable' event emission.
                            this.needReadable = false;
                            this.emittedReadable = false;
                            this.readableListening = false;

                            // object stream flag. Used to make read(n) ignore n and to
                            // make all the buffer merging and length checks go away
                            this.objectMode = !!options.objectMode;

                            // Crypto is kind of old and crusty.  Historically, its default string
                            // encoding is 'binary' so we have to make this configurable.
                            // Everything else in the universe uses 'utf8', though.
                            this.defaultEncoding = options.defaultEncoding || 'utf8';

                            // when piping, we only care about 'readable' events that happen
                            // after read()ing all the bytes and not getting any pushback.
                            this.ranOut = false;

                            // the number of writers that are awaiting a drain event in .pipe()s
                            this.awaitDrain = 0;

                            // if true, a maybeReadMore has been scheduled
                            this.readingMore = false;

                            this.decoder = null;
                            this.encoding = null;
                            if (options.encoding) {
                                if (!StringDecoder) StringDecoder = require('string_decoder/').StringDecoder;
                                this.decoder = new StringDecoder(options.encoding);
                                this.encoding = options.encoding;
                            }
                        }

                        function Readable(options) {
                            if (!(this instanceof Readable)) return new Readable(options);

                            this._readableState = new ReadableState(options, this);

                            // legacy
                            this.readable = true;

                            Stream.call(this);
                        }

                        // Manually shove something into the read() buffer.
                        // This returns true if the highWaterMark has not been hit yet,
                        // similar to how Writable.write() returns true if you should
                        // write() some more.
                        Readable.prototype.push = function (chunk, encoding) {
                            var state = this._readableState;

                            if (typeof chunk === 'string' && !state.objectMode) {
                                encoding = encoding || state.defaultEncoding;
                                if (encoding !== state.encoding) {
                                    chunk = new Buffer(chunk, encoding);
                                    encoding = '';
                                }
                            }

                            return readableAddChunk(this, state, chunk, encoding, false);
                        };

                        // Unshift should *always* be something directly out of read()
                        Readable.prototype.unshift = function (chunk) {
                            var state = this._readableState;
                            return readableAddChunk(this, state, chunk, '', true);
                        };

                        function readableAddChunk(stream, state, chunk, encoding, addToFront) {
                            var er = chunkInvalid(state, chunk);
                            if (er) {
                                stream.emit('error', er);
                            } else if (chunk === null || chunk === undefined) {
                                state.reading = false;
                                if (!state.ended) onEofChunk(stream, state);
                            } else if (state.objectMode || (chunk && chunk.length > 0)) {
                                if (state.ended && !addToFront) {
                                    var e = new Error('stream.push() after EOF');
                                    stream.emit('error', e);
                                } else if (state.endEmitted && addToFront) {
                                    var e = new Error('stream.unshift() after end event');
                                    stream.emit('error', e);
                                } else {
                                    if (state.decoder && !addToFront && !encoding) chunk = state.decoder.write(chunk);

                                    // update the buffer info.
                                    state.length += state.objectMode ? 1 : chunk.length;
                                    if (addToFront) {
                                        state.buffer.unshift(chunk);
                                    } else {
                                        state.reading = false;
                                        state.buffer.push(chunk);
                                    }

                                    if (state.needReadable) emitReadable(stream);

                                    maybeReadMore(stream, state);
                                }
                            } else if (!addToFront) {
                                state.reading = false;
                            }

                            return needMoreData(state);
                        }

                        // if it's past the high water mark, we can push in some more.
                        // Also, if we have no data yet, we can stand some
                        // more bytes.  This is to work around cases where hwm=0,
                        // such as the repl.  Also, if the push() triggered a
                        // readable event, and the user called read(largeNumber) such that
                        // needReadable was set, then we ought to push more, so that another
                        // 'readable' event will be triggered.
                        function needMoreData(state) {
                            return (
                                !state.ended &&
                                (state.needReadable || state.length < state.highWaterMark || state.length === 0)
                            );
                        }

                        // backwards compatibility.
                        Readable.prototype.setEncoding = function (enc) {
                            if (!StringDecoder) StringDecoder = require('string_decoder/').StringDecoder;
                            this._readableState.decoder = new StringDecoder(enc);
                            this._readableState.encoding = enc;
                        };

                        // Don't raise the hwm > 128MB
                        var MAX_HWM = 0x800000;
                        function roundUpToNextPowerOf2(n) {
                            if (n >= MAX_HWM) {
                                n = MAX_HWM;
                            } else {
                                // Get the next highest power of 2
                                n--;
                                for (var p = 1; p < 32; p <<= 1) n |= n >> p;
                                n++;
                            }
                            return n;
                        }

                        function howMuchToRead(n, state) {
                            if (state.length === 0 && state.ended) return 0;

                            if (state.objectMode) return n === 0 ? 0 : 1;

                            if (n === null || isNaN(n)) {
                                // only flow one buffer at a time
                                if (state.flowing && state.buffer.length) return state.buffer[0].length;
                                else return state.length;
                            }

                            if (n <= 0) return 0;

                            // If we're asking for more than the target buffer level,
                            // then raise the water mark.  Bump up to the next highest
                            // power of 2, to prevent increasing it excessively in tiny
                            // amounts.
                            if (n > state.highWaterMark) state.highWaterMark = roundUpToNextPowerOf2(n);

                            // don't have that much.  return null, unless we've ended.
                            if (n > state.length) {
                                if (!state.ended) {
                                    state.needReadable = true;
                                    return 0;
                                } else return state.length;
                            }

                            return n;
                        }

                        // you can override either this method, or the async _read(n) below.
                        Readable.prototype.read = function (n) {
                            var state = this._readableState;
                            state.calledRead = true;
                            var nOrig = n;
                            var ret;

                            if (typeof n !== 'number' || n > 0) state.emittedReadable = false;

                            // if we're doing read(0) to trigger a readable event, but we
                            // already have a bunch of data in the buffer, then just trigger
                            // the 'readable' event and move on.
                            if (n === 0 && state.needReadable && (state.length >= state.highWaterMark || state.ended)) {
                                emitReadable(this);
                                return null;
                            }

                            n = howMuchToRead(n, state);

                            // if we've ended, and we're now clear, then finish it up.
                            if (n === 0 && state.ended) {
                                ret = null;

                                // In cases where the decoder did not receive enough data
                                // to produce a full chunk, then immediately received an
                                // EOF, state.buffer will contain [<Buffer >, <Buffer 00 ...>].
                                // howMuchToRead will see this and coerce the amount to
                                // read to zero (because it's looking at the length of the
                                // first <Buffer > in state.buffer), and we'll end up here.
                                //
                                // This can only happen via state.decoder -- no other venue
                                // exists for pushing a zero-length chunk into state.buffer
                                // and triggering this behavior. In this case, we return our
                                // remaining data and end the stream, if appropriate.
                                if (state.length > 0 && state.decoder) {
                                    ret = fromList(n, state);
                                    state.length -= ret.length;
                                }

                                if (state.length === 0) endReadable(this);

                                return ret;
                            }

                            // All the actual chunk generation logic needs to be
                            // *below* the call to _read.  The reason is that in certain
                            // synthetic stream cases, such as passthrough streams, _read
                            // may be a completely synchronous operation which may change
                            // the state of the read buffer, providing enough data when
                            // before there was *not* enough.
                            //
                            // So, the steps are:
                            // 1. Figure out what the state of things will be after we do
                            // a read from the buffer.
                            //
                            // 2. If that resulting state will trigger a _read, then call _read.
                            // Note that this may be asynchronous, or synchronous.  Yes, it is
                            // deeply ugly to write APIs this way, but that still doesn't mean
                            // that the Readable class should behave improperly, as streams are
                            // designed to be sync/async agnostic.
                            // Take note if the _read call is sync or async (ie, if the read call
                            // has returned yet), so that we know whether or not it's safe to emit
                            // 'readable' etc.
                            //
                            // 3. Actually pull the requested chunks out of the buffer and return.

                            // if we need a readable event, then we need to do some reading.
                            var doRead = state.needReadable;

                            // if we currently have less than the highWaterMark, then also read some
                            if (state.length - n <= state.highWaterMark) doRead = true;

                            // however, if we've ended, then there's no point, and if we're already
                            // reading, then it's unnecessary.
                            if (state.ended || state.reading) doRead = false;

                            if (doRead) {
                                state.reading = true;
                                state.sync = true;
                                // if the length is currently zero, then we *need* a readable event.
                                if (state.length === 0) state.needReadable = true;
                                // call internal read method
                                this._read(state.highWaterMark);
                                state.sync = false;
                            }

                            // If _read called its callback synchronously, then `reading`
                            // will be false, and we need to re-evaluate how much data we
                            // can return to the user.
                            if (doRead && !state.reading) n = howMuchToRead(nOrig, state);

                            if (n > 0) ret = fromList(n, state);
                            else ret = null;

                            if (ret === null) {
                                state.needReadable = true;
                                n = 0;
                            }

                            state.length -= n;

                            // If we have nothing in the buffer, then we want to know
                            // as soon as we *do* get something into the buffer.
                            if (state.length === 0 && !state.ended) state.needReadable = true;

                            // If we happened to read() exactly the remaining amount in the
                            // buffer, and the EOF has been seen at this point, then make sure
                            // that we emit 'end' on the very next tick.
                            if (state.ended && !state.endEmitted && state.length === 0) endReadable(this);

                            return ret;
                        };

                        function chunkInvalid(state, chunk) {
                            var er = null;
                            if (
                                !Buffer.isBuffer(chunk) &&
                                'string' !== typeof chunk &&
                                chunk !== null &&
                                chunk !== undefined &&
                                !state.objectMode
                            ) {
                                er = new TypeError('Invalid non-string/buffer chunk');
                            }
                            return er;
                        }

                        function onEofChunk(stream, state) {
                            if (state.decoder && !state.ended) {
                                var chunk = state.decoder.end();
                                if (chunk && chunk.length) {
                                    state.buffer.push(chunk);
                                    state.length += state.objectMode ? 1 : chunk.length;
                                }
                            }
                            state.ended = true;

                            // if we've ended and we have some data left, then emit
                            // 'readable' now to make sure it gets picked up.
                            if (state.length > 0) emitReadable(stream);
                            else endReadable(stream);
                        }

                        // Don't emit readable right away in sync mode, because this can trigger
                        // another read() call => stack overflow.  This way, it might trigger
                        // a nextTick recursion warning, but that's not so bad.
                        function emitReadable(stream) {
                            var state = stream._readableState;
                            state.needReadable = false;
                            if (state.emittedReadable) return;

                            state.emittedReadable = true;
                            if (state.sync)
                                process.nextTick(function () {
                                    emitReadable_(stream);
                                });
                            else emitReadable_(stream);
                        }

                        function emitReadable_(stream) {
                            stream.emit('readable');
                        }

                        // at this point, the user has presumably seen the 'readable' event,
                        // and called read() to consume some data.  that may have triggered
                        // in turn another _read(n) call, in which case reading = true if
                        // it's in progress.
                        // However, if we're not ended, or reading, and the length < hwm,
                        // then go ahead and try to read some more preemptively.
                        function maybeReadMore(stream, state) {
                            if (!state.readingMore) {
                                state.readingMore = true;
                                process.nextTick(function () {
                                    maybeReadMore_(stream, state);
                                });
                            }
                        }

                        function maybeReadMore_(stream, state) {
                            var len = state.length;
                            while (
                                !state.reading &&
                                !state.flowing &&
                                !state.ended &&
                                state.length < state.highWaterMark
                            ) {
                                stream.read(0);
                                if (len === state.length)
                                    // didn't get any data, stop spinning.
                                    break;
                                else len = state.length;
                            }
                            state.readingMore = false;
                        }

                        // abstract method.  to be overridden in specific implementation classes.
                        // call cb(er, data) where data is <= n in length.
                        // for virtual (non-string, non-buffer) streams, "length" is somewhat
                        // arbitrary, and perhaps not very meaningful.
                        Readable.prototype._read = function (n) {
                            this.emit('error', new Error('not implemented'));
                        };

                        Readable.prototype.pipe = function (dest, pipeOpts) {
                            var src = this;
                            var state = this._readableState;

                            switch (state.pipesCount) {
                                case 0:
                                    state.pipes = dest;
                                    break;
                                case 1:
                                    state.pipes = [state.pipes, dest];
                                    break;
                                default:
                                    state.pipes.push(dest);
                                    break;
                            }
                            state.pipesCount += 1;

                            var doEnd =
                                (!pipeOpts || pipeOpts.end !== false) &&
                                dest !== process.stdout &&
                                dest !== process.stderr;

                            var endFn = doEnd ? onend : cleanup;
                            if (state.endEmitted) process.nextTick(endFn);
                            else src.once('end', endFn);

                            dest.on('unpipe', onunpipe);
                            function onunpipe(readable) {
                                if (readable !== src) return;
                                cleanup();
                            }

                            function onend() {
                                dest.end();
                            }

                            // when the dest drains, it reduces the awaitDrain counter
                            // on the source.  This would be more elegant with a .once()
                            // handler in flow(), but adding and removing repeatedly is
                            // too slow.
                            var ondrain = pipeOnDrain(src);
                            dest.on('drain', ondrain);

                            function cleanup() {
                                // cleanup event handlers once the pipe is broken
                                dest.removeListener('close', onclose);
                                dest.removeListener('finish', onfinish);
                                dest.removeListener('drain', ondrain);
                                dest.removeListener('error', onerror);
                                dest.removeListener('unpipe', onunpipe);
                                src.removeListener('end', onend);
                                src.removeListener('end', cleanup);

                                // if the reader is waiting for a drain event from this
                                // specific writer, then it would cause it to never start
                                // flowing again.
                                // So, if this is awaiting a drain, then we just call it now.
                                // If we don't know, then assume that we are waiting for one.
                                if (!dest._writableState || dest._writableState.needDrain) ondrain();
                            }

                            // if the dest has an error, then stop piping into it.
                            // however, don't suppress the throwing behavior for this.
                            function onerror(er) {
                                unpipe();
                                dest.removeListener('error', onerror);
                                if (EE.listenerCount(dest, 'error') === 0) dest.emit('error', er);
                            }
                            // This is a brutally ugly hack to make sure that our error handler
                            // is attached before any userland ones.  NEVER DO THIS.
                            if (!dest._events || !dest._events.error) dest.on('error', onerror);
                            else if (isArray(dest._events.error)) dest._events.error.unshift(onerror);
                            else dest._events.error = [onerror, dest._events.error];

                            // Both close and finish should trigger unpipe, but only once.
                            function onclose() {
                                dest.removeListener('finish', onfinish);
                                unpipe();
                            }
                            dest.once('close', onclose);
                            function onfinish() {
                                dest.removeListener('close', onclose);
                                unpipe();
                            }
                            dest.once('finish', onfinish);

                            function unpipe() {
                                src.unpipe(dest);
                            }

                            // tell the dest that it's being piped to
                            dest.emit('pipe', src);

                            // start the flow if it hasn't been started already.
                            if (!state.flowing) {
                                // the handler that waits for readable events after all
                                // the data gets sucked out in flow.
                                // This would be easier to follow with a .once() handler
                                // in flow(), but that is too slow.
                                this.on('readable', pipeOnReadable);

                                state.flowing = true;
                                process.nextTick(function () {
                                    flow(src);
                                });
                            }

                            return dest;
                        };

                        function pipeOnDrain(src) {
                            return function () {
                                var dest = this;
                                var state = src._readableState;
                                state.awaitDrain--;
                                if (state.awaitDrain === 0) flow(src);
                            };
                        }

                        function flow(src) {
                            var state = src._readableState;
                            var chunk;
                            state.awaitDrain = 0;

                            function write(dest, i, list) {
                                var written = dest.write(chunk);
                                if (false === written) {
                                    state.awaitDrain++;
                                }
                            }

                            while (state.pipesCount && null !== (chunk = src.read())) {
                                if (state.pipesCount === 1) write(state.pipes, 0, null);
                                else forEach(state.pipes, write);

                                src.emit('data', chunk);

                                // if anyone needs a drain, then we have to wait for that.
                                if (state.awaitDrain > 0) return;
                            }

                            // if every destination was unpiped, either before entering this
                            // function, or in the while loop, then stop flowing.
                            //
                            // NB: This is a pretty rare edge case.
                            if (state.pipesCount === 0) {
                                state.flowing = false;

                                // if there were data event listeners added, then switch to old mode.
                                if (EE.listenerCount(src, 'data') > 0) emitDataEvents(src);
                                return;
                            }

                            // at this point, no one needed a drain, so we just ran out of data
                            // on the next readable event, start it over again.
                            state.ranOut = true;
                        }

                        function pipeOnReadable() {
                            if (this._readableState.ranOut) {
                                this._readableState.ranOut = false;
                                flow(this);
                            }
                        }

                        Readable.prototype.unpipe = function (dest) {
                            var state = this._readableState;

                            // if we're not piping anywhere, then do nothing.
                            if (state.pipesCount === 0) return this;

                            // just one destination.  most common case.
                            if (state.pipesCount === 1) {
                                // passed in one, but it's not the right one.
                                if (dest && dest !== state.pipes) return this;

                                if (!dest) dest = state.pipes;

                                // got a match.
                                state.pipes = null;
                                state.pipesCount = 0;
                                this.removeListener('readable', pipeOnReadable);
                                state.flowing = false;
                                if (dest) dest.emit('unpipe', this);
                                return this;
                            }

                            // slow case. multiple pipe destinations.

                            if (!dest) {
                                // remove all.
                                var dests = state.pipes;
                                var len = state.pipesCount;
                                state.pipes = null;
                                state.pipesCount = 0;
                                this.removeListener('readable', pipeOnReadable);
                                state.flowing = false;

                                for (var i = 0; i < len; i++) dests[i].emit('unpipe', this);
                                return this;
                            }

                            // try to find the right one.
                            var i = indexOf(state.pipes, dest);
                            if (i === -1) return this;

                            state.pipes.splice(i, 1);
                            state.pipesCount -= 1;
                            if (state.pipesCount === 1) state.pipes = state.pipes[0];

                            dest.emit('unpipe', this);

                            return this;
                        };

                        // set up data events if they are asked for
                        // Ensure readable listeners eventually get something
                        Readable.prototype.on = function (ev, fn) {
                            var res = Stream.prototype.on.call(this, ev, fn);

                            if (ev === 'data' && !this._readableState.flowing) emitDataEvents(this);

                            if (ev === 'readable' && this.readable) {
                                var state = this._readableState;
                                if (!state.readableListening) {
                                    state.readableListening = true;
                                    state.emittedReadable = false;
                                    state.needReadable = true;
                                    if (!state.reading) {
                                        this.read(0);
                                    } else if (state.length) {
                                        emitReadable(this, state);
                                    }
                                }
                            }

                            return res;
                        };
                        Readable.prototype.addListener = Readable.prototype.on;

                        // pause() and resume() are remnants of the legacy readable stream API
                        // If the user uses them, then switch into old mode.
                        Readable.prototype.resume = function () {
                            emitDataEvents(this);
                            this.read(0);
                            this.emit('resume');
                        };

                        Readable.prototype.pause = function () {
                            emitDataEvents(this, true);
                            this.emit('pause');
                        };

                        function emitDataEvents(stream, startPaused) {
                            var state = stream._readableState;

                            if (state.flowing) {
                                // https://github.com/isaacs/readable-stream/issues/16
                                throw new Error('Cannot switch to old mode now.');
                            }

                            var paused = startPaused || false;
                            var readable = false;

                            // convert to an old-style stream.
                            stream.readable = true;
                            stream.pipe = Stream.prototype.pipe;
                            stream.on = stream.addListener = Stream.prototype.on;

                            stream.on('readable', function () {
                                readable = true;

                                var c;
                                while (!paused && null !== (c = stream.read())) stream.emit('data', c);

                                if (c === null) {
                                    readable = false;
                                    stream._readableState.needReadable = true;
                                }
                            });

                            stream.pause = function () {
                                paused = true;
                                this.emit('pause');
                            };

                            stream.resume = function () {
                                paused = false;
                                if (readable)
                                    process.nextTick(function () {
                                        stream.emit('readable');
                                    });
                                else this.read(0);
                                this.emit('resume');
                            };

                            // now make it start, just in case it hadn't already.
                            stream.emit('readable');
                        }

                        // wrap an old-style stream as the async data source.
                        // This is *not* part of the readable stream interface.
                        // It is an ugly unfortunate mess of history.
                        Readable.prototype.wrap = function (stream) {
                            var state = this._readableState;
                            var paused = false;

                            var self = this;
                            stream.on('end', function () {
                                if (state.decoder && !state.ended) {
                                    var chunk = state.decoder.end();
                                    if (chunk && chunk.length) self.push(chunk);
                                }

                                self.push(null);
                            });

                            stream.on('data', function (chunk) {
                                if (state.decoder) chunk = state.decoder.write(chunk);

                                // don't skip over falsy values in objectMode
                                //if (state.objectMode && util.isNullOrUndefined(chunk))
                                if (state.objectMode && (chunk === null || chunk === undefined)) return;
                                else if (!state.objectMode && (!chunk || !chunk.length)) return;

                                var ret = self.push(chunk);
                                if (!ret) {
                                    paused = true;
                                    stream.pause();
                                }
                            });

                            // proxy all the other methods.
                            // important when wrapping filters and duplexes.
                            for (var i in stream) {
                                if (typeof stream[i] === 'function' && typeof this[i] === 'undefined') {
                                    this[i] = (function (method) {
                                        return function () {
                                            return stream[method].apply(stream, arguments);
                                        };
                                    })(i);
                                }
                            }

                            // proxy certain important events.
                            var events = ['error', 'close', 'destroy', 'pause', 'resume'];
                            forEach(events, function (ev) {
                                stream.on(ev, self.emit.bind(self, ev));
                            });

                            // when we try to consume some more bytes, simply unpause the
                            // underlying stream.
                            self._read = function (n) {
                                if (paused) {
                                    paused = false;
                                    stream.resume();
                                }
                            };

                            return self;
                        };

                        // exposed for testing purposes only.
                        Readable._fromList = fromList;

                        // Pluck off n bytes from an array of buffers.
                        // Length is the combined lengths of all the buffers in the list.
                        function fromList(n, state) {
                            var list = state.buffer;
                            var length = state.length;
                            var stringMode = !!state.decoder;
                            var objectMode = !!state.objectMode;
                            var ret;

                            // nothing in the list, definitely empty.
                            if (list.length === 0) return null;

                            if (length === 0) ret = null;
                            else if (objectMode) ret = list.shift();
                            else if (!n || n >= length) {
                                // read it all, truncate the array.
                                if (stringMode) ret = list.join('');
                                else ret = Buffer.concat(list, length);
                                list.length = 0;
                            } else {
                                // read just some of it.
                                if (n < list[0].length) {
                                    // just take a part of the first list item.
                                    // slice is the same for buffers and strings.
                                    var buf = list[0];
                                    ret = buf.slice(0, n);
                                    list[0] = buf.slice(n);
                                } else if (n === list[0].length) {
                                    // first list is a perfect match
                                    ret = list.shift();
                                } else {
                                    // complex case.
                                    // we have enough to cover it, but it spans past the first buffer.
                                    if (stringMode) ret = '';
                                    else ret = new Buffer(n);

                                    var c = 0;
                                    for (var i = 0, l = list.length; i < l && c < n; i++) {
                                        var buf = list[0];
                                        var cpy = Math.min(n - c, buf.length);

                                        if (stringMode) ret += buf.slice(0, cpy);
                                        else buf.copy(ret, c, 0, cpy);

                                        if (cpy < buf.length) list[0] = buf.slice(cpy);
                                        else list.shift();

                                        c += cpy;
                                    }
                                }
                            }

                            return ret;
                        }

                        function endReadable(stream) {
                            var state = stream._readableState;

                            // If we get here before consuming all the bytes, then that is a
                            // bug in node.  Should never happen.
                            if (state.length > 0) throw new Error('endReadable called on non-empty stream');

                            if (!state.endEmitted && state.calledRead) {
                                state.ended = true;
                                process.nextTick(function () {
                                    // Check that we didn't get one last unshift.
                                    if (!state.endEmitted && state.length === 0) {
                                        state.endEmitted = true;
                                        stream.readable = false;
                                        stream.emit('end');
                                    }
                                });
                            }
                        }

                        function forEach(xs, f) {
                            for (var i = 0, l = xs.length; i < l; i++) {
                                f(xs[i], i);
                            }
                        }

                        function indexOf(xs, x) {
                            for (var i = 0, l = xs.length; i < l; i++) {
                                if (xs[i] === x) return i;
                            }
                            return -1;
                        }
                    }.call(this, require('_process')));
                },
                {
                    _process: 10,
                    buffer: 3,
                    'core-util-is': 17,
                    events: 7,
                    inherits: 8,
                    isarray: 9,
                    stream: 22,
                    'string_decoder/': 23,
                },
            ],
            15: [
                function (require, module, exports) {
                    // Copyright Joyent, Inc. and other Node contributors.
                    //
                    // Permission is hereby granted, free of charge, to any person obtaining a
                    // copy of this software and associated documentation files (the
                    // "Software"), to deal in the Software without restriction, including
                    // without limitation the rights to use, copy, modify, merge, publish,
                    // distribute, sublicense, and/or sell copies of the Software, and to permit
                    // persons to whom the Software is furnished to do so, subject to the
                    // following conditions:
                    //
                    // The above copyright notice and this permission notice shall be included
                    // in all copies or substantial portions of the Software.
                    //
                    // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                    // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                    // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                    // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                    // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                    // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                    // USE OR OTHER DEALINGS IN THE SOFTWARE.

                    // a transform stream is a readable/writable stream where you do
                    // something with the data.  Sometimes it's called a "filter",
                    // but that's not a great name for it, since that implies a thing where
                    // some bits pass through, and others are simply ignored.  (That would
                    // be a valid example of a transform, of course.)
                    //
                    // While the output is causally related to the input, it's not a
                    // necessarily symmetric or synchronous transformation.  For example,
                    // a zlib stream might take multiple plain-text writes(), and then
                    // emit a single compressed chunk some time in the future.
                    //
                    // Here's how this works:
                    //
                    // The Transform stream has all the aspects of the readable and writable
                    // stream classes.  When you write(chunk), that calls _write(chunk,cb)
                    // internally, and returns false if there's a lot of pending writes
                    // buffered up.  When you call read(), that calls _read(n) until
                    // there's enough pending readable data buffered up.
                    //
                    // In a transform stream, the written data is placed in a buffer.  When
                    // _read(n) is called, it transforms the queued up data, calling the
                    // buffered _write cb's as it consumes chunks.  If consuming a single
                    // written chunk would result in multiple output chunks, then the first
                    // outputted bit calls the readcb, and subsequent chunks just go into
                    // the read buffer, and will cause it to emit 'readable' if necessary.
                    //
                    // This way, back-pressure is actually determined by the reading side,
                    // since _read has to be called to start processing a new chunk.  However,
                    // a pathological inflate type of transform can cause excessive buffering
                    // here.  For example, imagine a stream where every byte of input is
                    // interpreted as an integer from 0-255, and then results in that many
                    // bytes of output.  Writing the 4 bytes {ff,ff,ff,ff} would result in
                    // 1kb of data being output.  In this case, you could write a very small
                    // amount of input, and end up with a very large amount of output.  In
                    // such a pathological inflating mechanism, there'd be no way to tell
                    // the system to stop doing the transform.  A single 4MB write could
                    // cause the system to run out of memory.
                    //
                    // However, even in such a pathological case, only a single written chunk
                    // would be consumed, and then the rest would wait (un-transformed) until
                    // the results of the previous transformed chunk were consumed.

                    module.exports = Transform;

                    var Duplex = require('./_stream_duplex');

                    /*<replacement>*/
                    var util = require('core-util-is');
                    util.inherits = require('inherits');
                    /*</replacement>*/

                    util.inherits(Transform, Duplex);

                    function TransformState(options, stream) {
                        this.afterTransform = function (er, data) {
                            return afterTransform(stream, er, data);
                        };

                        this.needTransform = false;
                        this.transforming = false;
                        this.writecb = null;
                        this.writechunk = null;
                    }

                    function afterTransform(stream, er, data) {
                        var ts = stream._transformState;
                        ts.transforming = false;

                        var cb = ts.writecb;

                        if (!cb) return stream.emit('error', new Error('no writecb in Transform class'));

                        ts.writechunk = null;
                        ts.writecb = null;

                        if (data !== null && data !== undefined) stream.push(data);

                        if (cb) cb(er);

                        var rs = stream._readableState;
                        rs.reading = false;
                        if (rs.needReadable || rs.length < rs.highWaterMark) {
                            stream._read(rs.highWaterMark);
                        }
                    }

                    function Transform(options) {
                        if (!(this instanceof Transform)) return new Transform(options);

                        Duplex.call(this, options);

                        var ts = (this._transformState = new TransformState(options, this));

                        // when the writable side finishes, then flush out anything remaining.
                        var stream = this;

                        // start out asking for a readable event once data is transformed.
                        this._readableState.needReadable = true;

                        // we have implemented the _read method, and done the other things
                        // that Readable wants before the first _read call, so unset the
                        // sync guard flag.
                        this._readableState.sync = false;

                        this.once('finish', function () {
                            if ('function' === typeof this._flush)
                                this._flush(function (er) {
                                    done(stream, er);
                                });
                            else done(stream);
                        });
                    }

                    Transform.prototype.push = function (chunk, encoding) {
                        this._transformState.needTransform = false;
                        return Duplex.prototype.push.call(this, chunk, encoding);
                    };

                    // This is the part where you do stuff!
                    // override this function in implementation classes.
                    // 'chunk' is an input chunk.
                    //
                    // Call `push(newChunk)` to pass along transformed output
                    // to the readable side.  You may call 'push' zero or more times.
                    //
                    // Call `cb(err)` when you are done with this chunk.  If you pass
                    // an error, then that'll put the hurt on the whole operation.  If you
                    // never call cb(), then you'll never get another chunk.
                    Transform.prototype._transform = function (chunk, encoding, cb) {
                        throw new Error('not implemented');
                    };

                    Transform.prototype._write = function (chunk, encoding, cb) {
                        var ts = this._transformState;
                        ts.writecb = cb;
                        ts.writechunk = chunk;
                        ts.writeencoding = encoding;
                        if (!ts.transforming) {
                            var rs = this._readableState;
                            if (ts.needTransform || rs.needReadable || rs.length < rs.highWaterMark)
                                this._read(rs.highWaterMark);
                        }
                    };

                    // Doesn't matter what the args are here.
                    // _transform does all the work.
                    // That we got here means that the readable side wants more data.
                    Transform.prototype._read = function (n) {
                        var ts = this._transformState;

                        if (ts.writechunk !== null && ts.writecb && !ts.transforming) {
                            ts.transforming = true;
                            this._transform(ts.writechunk, ts.writeencoding, ts.afterTransform);
                        } else {
                            // mark that we need a transform, so that any data that comes in
                            // will get processed, now that we've asked for it.
                            ts.needTransform = true;
                        }
                    };

                    function done(stream, er) {
                        if (er) return stream.emit('error', er);

                        // if there's nothing in the write buffer, then that means
                        // that nothing more will ever be provided
                        var ws = stream._writableState;
                        var rs = stream._readableState;
                        var ts = stream._transformState;

                        if (ws.length) throw new Error('calling transform done when ws.length != 0');

                        if (ts.transforming) throw new Error('calling transform done when still transforming');

                        return stream.push(null);
                    }
                },
                { './_stream_duplex': 12, 'core-util-is': 17, inherits: 8 },
            ],
            16: [
                function (require, module, exports) {
                    (function (process) {
                        // Copyright Joyent, Inc. and other Node contributors.
                        //
                        // Permission is hereby granted, free of charge, to any person obtaining a
                        // copy of this software and associated documentation files (the
                        // "Software"), to deal in the Software without restriction, including
                        // without limitation the rights to use, copy, modify, merge, publish,
                        // distribute, sublicense, and/or sell copies of the Software, and to permit
                        // persons to whom the Software is furnished to do so, subject to the
                        // following conditions:
                        //
                        // The above copyright notice and this permission notice shall be included
                        // in all copies or substantial portions of the Software.
                        //
                        // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                        // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                        // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                        // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                        // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                        // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                        // USE OR OTHER DEALINGS IN THE SOFTWARE.

                        // A bit simpler than readable streams.
                        // Implement an async ._write(chunk, cb), and it'll handle all
                        // the drain event emission and buffering.

                        module.exports = Writable;

                        /*<replacement>*/
                        var Buffer = require('buffer').Buffer;
                        /*</replacement>*/

                        Writable.WritableState = WritableState;

                        /*<replacement>*/
                        var util = require('core-util-is');
                        util.inherits = require('inherits');
                        /*</replacement>*/

                        var Stream = require('stream');

                        util.inherits(Writable, Stream);

                        function WriteReq(chunk, encoding, cb) {
                            this.chunk = chunk;
                            this.encoding = encoding;
                            this.callback = cb;
                        }

                        function WritableState(options, stream) {
                            options = options || {};

                            // the point at which write() starts returning false
                            // Note: 0 is a valid value, means that we always return false if
                            // the entire buffer is not flushed immediately on write()
                            var hwm = options.highWaterMark;
                            this.highWaterMark = hwm || hwm === 0 ? hwm : 16 * 1024;

                            // object stream flag to indicate whether or not this stream
                            // contains buffers or objects.
                            this.objectMode = !!options.objectMode;

                            // cast to ints.
                            this.highWaterMark = ~~this.highWaterMark;

                            this.needDrain = false;
                            // at the start of calling end()
                            this.ending = false;
                            // when end() has been called, and returned
                            this.ended = false;
                            // when 'finish' is emitted
                            this.finished = false;

                            // should we decode strings into buffers before passing to _write?
                            // this is here so that some node-core streams can optimize string
                            // handling at a lower level.
                            var noDecode = options.decodeStrings === false;
                            this.decodeStrings = !noDecode;

                            // Crypto is kind of old and crusty.  Historically, its default string
                            // encoding is 'binary' so we have to make this configurable.
                            // Everything else in the universe uses 'utf8', though.
                            this.defaultEncoding = options.defaultEncoding || 'utf8';

                            // not an actual buffer we keep track of, but a measurement
                            // of how much we're waiting to get pushed to some underlying
                            // socket or file.
                            this.length = 0;

                            // a flag to see when we're in the middle of a write.
                            this.writing = false;

                            // a flag to be able to tell if the onwrite cb is called immediately,
                            // or on a later tick.  We set this to true at first, becuase any
                            // actions that shouldn't happen until "later" should generally also
                            // not happen before the first write call.
                            this.sync = true;

                            // a flag to know if we're processing previously buffered items, which
                            // may call the _write() callback in the same tick, so that we don't
                            // end up in an overlapped onwrite situation.
                            this.bufferProcessing = false;

                            // the callback that's passed to _write(chunk,cb)
                            this.onwrite = function (er) {
                                onwrite(stream, er);
                            };

                            // the callback that the user supplies to write(chunk,encoding,cb)
                            this.writecb = null;

                            // the amount that is being written when _write is called.
                            this.writelen = 0;

                            this.buffer = [];

                            // True if the error was already emitted and should not be thrown again
                            this.errorEmitted = false;
                        }

                        function Writable(options) {
                            var Duplex = require('./_stream_duplex');

                            // Writable ctor is applied to Duplexes, though they're not
                            // instanceof Writable, they're instanceof Readable.
                            if (!(this instanceof Writable) && !(this instanceof Duplex)) return new Writable(options);

                            this._writableState = new WritableState(options, this);

                            // legacy.
                            this.writable = true;

                            Stream.call(this);
                        }

                        // Otherwise people can pipe Writable streams, which is just wrong.
                        Writable.prototype.pipe = function () {
                            this.emit('error', new Error('Cannot pipe. Not readable.'));
                        };

                        function writeAfterEnd(stream, state, cb) {
                            var er = new Error('write after end');
                            // TODO: defer error events consistently everywhere, not just the cb
                            stream.emit('error', er);
                            process.nextTick(function () {
                                cb(er);
                            });
                        }

                        // If we get something that is not a buffer, string, null, or undefined,
                        // and we're not in objectMode, then that's an error.
                        // Otherwise stream chunks are all considered to be of length=1, and the
                        // watermarks determine how many objects to keep in the buffer, rather than
                        // how many bytes or characters.
                        function validChunk(stream, state, chunk, cb) {
                            var valid = true;
                            if (
                                !Buffer.isBuffer(chunk) &&
                                'string' !== typeof chunk &&
                                chunk !== null &&
                                chunk !== undefined &&
                                !state.objectMode
                            ) {
                                var er = new TypeError('Invalid non-string/buffer chunk');
                                stream.emit('error', er);
                                process.nextTick(function () {
                                    cb(er);
                                });
                                valid = false;
                            }
                            return valid;
                        }

                        Writable.prototype.write = function (chunk, encoding, cb) {
                            var state = this._writableState;
                            var ret = false;

                            if (typeof encoding === 'function') {
                                cb = encoding;
                                encoding = null;
                            }

                            if (Buffer.isBuffer(chunk)) encoding = 'buffer';
                            else if (!encoding) encoding = state.defaultEncoding;

                            if (typeof cb !== 'function') cb = function () {};

                            if (state.ended) writeAfterEnd(this, state, cb);
                            else if (validChunk(this, state, chunk, cb))
                                ret = writeOrBuffer(this, state, chunk, encoding, cb);

                            return ret;
                        };

                        function decodeChunk(state, chunk, encoding) {
                            if (!state.objectMode && state.decodeStrings !== false && typeof chunk === 'string') {
                                chunk = new Buffer(chunk, encoding);
                            }
                            return chunk;
                        }

                        // if we're already writing something, then just put this
                        // in the queue, and wait our turn.  Otherwise, call _write
                        // If we return false, then we need a drain event, so set that flag.
                        function writeOrBuffer(stream, state, chunk, encoding, cb) {
                            chunk = decodeChunk(state, chunk, encoding);
                            if (Buffer.isBuffer(chunk)) encoding = 'buffer';
                            var len = state.objectMode ? 1 : chunk.length;

                            state.length += len;

                            var ret = state.length < state.highWaterMark;
                            // we must ensure that previous needDrain will not be reset to false.
                            if (!ret) state.needDrain = true;

                            if (state.writing) state.buffer.push(new WriteReq(chunk, encoding, cb));
                            else doWrite(stream, state, len, chunk, encoding, cb);

                            return ret;
                        }

                        function doWrite(stream, state, len, chunk, encoding, cb) {
                            state.writelen = len;
                            state.writecb = cb;
                            state.writing = true;
                            state.sync = true;
                            stream._write(chunk, encoding, state.onwrite);
                            state.sync = false;
                        }

                        function onwriteError(stream, state, sync, er, cb) {
                            if (sync)
                                process.nextTick(function () {
                                    cb(er);
                                });
                            else cb(er);

                            stream._writableState.errorEmitted = true;
                            stream.emit('error', er);
                        }

                        function onwriteStateUpdate(state) {
                            state.writing = false;
                            state.writecb = null;
                            state.length -= state.writelen;
                            state.writelen = 0;
                        }

                        function onwrite(stream, er) {
                            var state = stream._writableState;
                            var sync = state.sync;
                            var cb = state.writecb;

                            onwriteStateUpdate(state);

                            if (er) onwriteError(stream, state, sync, er, cb);
                            else {
                                // Check if we're actually ready to finish, but don't emit yet
                                var finished = needFinish(stream, state);

                                if (!finished && !state.bufferProcessing && state.buffer.length)
                                    clearBuffer(stream, state);

                                if (sync) {
                                    process.nextTick(function () {
                                        afterWrite(stream, state, finished, cb);
                                    });
                                } else {
                                    afterWrite(stream, state, finished, cb);
                                }
                            }
                        }

                        function afterWrite(stream, state, finished, cb) {
                            if (!finished) onwriteDrain(stream, state);
                            cb();
                            if (finished) finishMaybe(stream, state);
                        }

                        // Must force callback to be called on nextTick, so that we don't
                        // emit 'drain' before the write() consumer gets the 'false' return
                        // value, and has a chance to attach a 'drain' listener.
                        function onwriteDrain(stream, state) {
                            if (state.length === 0 && state.needDrain) {
                                state.needDrain = false;
                                stream.emit('drain');
                            }
                        }

                        // if there's something in the buffer waiting, then process it
                        function clearBuffer(stream, state) {
                            state.bufferProcessing = true;

                            for (var c = 0; c < state.buffer.length; c++) {
                                var entry = state.buffer[c];
                                var chunk = entry.chunk;
                                var encoding = entry.encoding;
                                var cb = entry.callback;
                                var len = state.objectMode ? 1 : chunk.length;

                                doWrite(stream, state, len, chunk, encoding, cb);

                                // if we didn't call the onwrite immediately, then
                                // it means that we need to wait until it does.
                                // also, that means that the chunk and cb are currently
                                // being processed, so move the buffer counter past them.
                                if (state.writing) {
                                    c++;
                                    break;
                                }
                            }

                            state.bufferProcessing = false;
                            if (c < state.buffer.length) state.buffer = state.buffer.slice(c);
                            else state.buffer.length = 0;
                        }

                        Writable.prototype._write = function (chunk, encoding, cb) {
                            cb(new Error('not implemented'));
                        };

                        Writable.prototype.end = function (chunk, encoding, cb) {
                            var state = this._writableState;

                            if (typeof chunk === 'function') {
                                cb = chunk;
                                chunk = null;
                                encoding = null;
                            } else if (typeof encoding === 'function') {
                                cb = encoding;
                                encoding = null;
                            }

                            if (typeof chunk !== 'undefined' && chunk !== null) this.write(chunk, encoding);

                            // ignore unnecessary end() calls.
                            if (!state.ending && !state.finished) endWritable(this, state, cb);
                        };

                        function needFinish(stream, state) {
                            return state.ending && state.length === 0 && !state.finished && !state.writing;
                        }

                        function finishMaybe(stream, state) {
                            var need = needFinish(stream, state);
                            if (need) {
                                state.finished = true;
                                stream.emit('finish');
                            }
                            return need;
                        }

                        function endWritable(stream, state, cb) {
                            state.ending = true;
                            finishMaybe(stream, state);
                            if (cb) {
                                if (state.finished) process.nextTick(cb);
                                else stream.once('finish', cb);
                            }
                            state.ended = true;
                        }
                    }.call(this, require('_process')));
                },
                { './_stream_duplex': 12, _process: 10, buffer: 3, 'core-util-is': 17, inherits: 8, stream: 22 },
            ],
            17: [
                function (require, module, exports) {
                    (function (Buffer) {
                        // Copyright Joyent, Inc. and other Node contributors.
                        //
                        // Permission is hereby granted, free of charge, to any person obtaining a
                        // copy of this software and associated documentation files (the
                        // "Software"), to deal in the Software without restriction, including
                        // without limitation the rights to use, copy, modify, merge, publish,
                        // distribute, sublicense, and/or sell copies of the Software, and to permit
                        // persons to whom the Software is furnished to do so, subject to the
                        // following conditions:
                        //
                        // The above copyright notice and this permission notice shall be included
                        // in all copies or substantial portions of the Software.
                        //
                        // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                        // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                        // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                        // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                        // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                        // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                        // USE OR OTHER DEALINGS IN THE SOFTWARE.

                        // NOTE: These type checking functions intentionally don't use `instanceof`
                        // because it is fragile and can be easily faked with `Object.create()`.
                        function isArray(ar) {
                            return Array.isArray(ar);
                        }
                        exports.isArray = isArray;

                        function isBoolean(arg) {
                            return typeof arg === 'boolean';
                        }
                        exports.isBoolean = isBoolean;

                        function isNull(arg) {
                            return arg === null;
                        }
                        exports.isNull = isNull;

                        function isNullOrUndefined(arg) {
                            return arg == null;
                        }
                        exports.isNullOrUndefined = isNullOrUndefined;

                        function isNumber(arg) {
                            return typeof arg === 'number';
                        }
                        exports.isNumber = isNumber;

                        function isString(arg) {
                            return typeof arg === 'string';
                        }
                        exports.isString = isString;

                        function isSymbol(arg) {
                            return typeof arg === 'symbol';
                        }
                        exports.isSymbol = isSymbol;

                        function isUndefined(arg) {
                            return arg === void 0;
                        }
                        exports.isUndefined = isUndefined;

                        function isRegExp(re) {
                            return isObject(re) && objectToString(re) === '[object RegExp]';
                        }
                        exports.isRegExp = isRegExp;

                        function isObject(arg) {
                            return typeof arg === 'object' && arg !== null;
                        }
                        exports.isObject = isObject;

                        function isDate(d) {
                            return isObject(d) && objectToString(d) === '[object Date]';
                        }
                        exports.isDate = isDate;

                        function isError(e) {
                            return isObject(e) && (objectToString(e) === '[object Error]' || e instanceof Error);
                        }
                        exports.isError = isError;

                        function isFunction(arg) {
                            return typeof arg === 'function';
                        }
                        exports.isFunction = isFunction;

                        function isPrimitive(arg) {
                            return (
                                arg === null ||
                                typeof arg === 'boolean' ||
                                typeof arg === 'number' ||
                                typeof arg === 'string' ||
                                typeof arg === 'symbol' || // ES6 symbol
                                typeof arg === 'undefined'
                            );
                        }
                        exports.isPrimitive = isPrimitive;

                        function isBuffer(arg) {
                            return Buffer.isBuffer(arg);
                        }
                        exports.isBuffer = isBuffer;

                        function objectToString(o) {
                            return Object.prototype.toString.call(o);
                        }
                    }.call(this, require('buffer').Buffer));
                },
                { buffer: 3 },
            ],
            18: [
                function (require, module, exports) {
                    module.exports = require('./lib/_stream_passthrough.js');
                },
                { './lib/_stream_passthrough.js': 13 },
            ],
            19: [
                function (require, module, exports) {
                    var Stream = require('stream'); // hack to fix a circular dependency issue when used with browserify
                    exports = module.exports = require('./lib/_stream_readable.js');
                    exports.Stream = Stream;
                    exports.Readable = exports;
                    exports.Writable = require('./lib/_stream_writable.js');
                    exports.Duplex = require('./lib/_stream_duplex.js');
                    exports.Transform = require('./lib/_stream_transform.js');
                    exports.PassThrough = require('./lib/_stream_passthrough.js');
                },
                {
                    './lib/_stream_duplex.js': 12,
                    './lib/_stream_passthrough.js': 13,
                    './lib/_stream_readable.js': 14,
                    './lib/_stream_transform.js': 15,
                    './lib/_stream_writable.js': 16,
                    stream: 22,
                },
            ],
            20: [
                function (require, module, exports) {
                    module.exports = require('./lib/_stream_transform.js');
                },
                { './lib/_stream_transform.js': 15 },
            ],
            21: [
                function (require, module, exports) {
                    module.exports = require('./lib/_stream_writable.js');
                },
                { './lib/_stream_writable.js': 16 },
            ],
            22: [
                function (require, module, exports) {
                    // Copyright Joyent, Inc. and other Node contributors.
                    //
                    // Permission is hereby granted, free of charge, to any person obtaining a
                    // copy of this software and associated documentation files (the
                    // "Software"), to deal in the Software without restriction, including
                    // without limitation the rights to use, copy, modify, merge, publish,
                    // distribute, sublicense, and/or sell copies of the Software, and to permit
                    // persons to whom the Software is furnished to do so, subject to the
                    // following conditions:
                    //
                    // The above copyright notice and this permission notice shall be included
                    // in all copies or substantial portions of the Software.
                    //
                    // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                    // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                    // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                    // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                    // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                    // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                    // USE OR OTHER DEALINGS IN THE SOFTWARE.

                    module.exports = Stream;

                    var EE = require('events').EventEmitter;
                    var inherits = require('inherits');

                    inherits(Stream, EE);
                    Stream.Readable = require('readable-stream/readable.js');
                    Stream.Writable = require('readable-stream/writable.js');
                    Stream.Duplex = require('readable-stream/duplex.js');
                    Stream.Transform = require('readable-stream/transform.js');
                    Stream.PassThrough = require('readable-stream/passthrough.js');

                    // Backwards-compat with node 0.4.x
                    Stream.Stream = Stream;

                    // old-style streams.  Note that the pipe method (the only relevant
                    // part of this class) is overridden in the Readable class.

                    function Stream() {
                        EE.call(this);
                    }

                    Stream.prototype.pipe = function (dest, options) {
                        var source = this;

                        function ondata(chunk) {
                            if (dest.writable) {
                                if (false === dest.write(chunk) && source.pause) {
                                    source.pause();
                                }
                            }
                        }

                        source.on('data', ondata);

                        function ondrain() {
                            if (source.readable && source.resume) {
                                source.resume();
                            }
                        }

                        dest.on('drain', ondrain);

                        // If the 'end' option is not supplied, dest.end() will be called when
                        // source gets the 'end' or 'close' events.  Only dest.end() once.
                        if (!dest._isStdio && (!options || options.end !== false)) {
                            source.on('end', onend);
                            source.on('close', onclose);
                        }

                        var didOnEnd = false;
                        function onend() {
                            if (didOnEnd) return;
                            didOnEnd = true;

                            dest.end();
                        }

                        function onclose() {
                            if (didOnEnd) return;
                            didOnEnd = true;

                            if (typeof dest.destroy === 'function') dest.destroy();
                        }

                        // don't leave dangling pipes when there are errors.
                        function onerror(er) {
                            cleanup();
                            if (EE.listenerCount(this, 'error') === 0) {
                                throw er; // Unhandled stream error in pipe.
                            }
                        }

                        source.on('error', onerror);
                        dest.on('error', onerror);

                        // remove all the event listeners that were added.
                        function cleanup() {
                            source.removeListener('data', ondata);
                            dest.removeListener('drain', ondrain);

                            source.removeListener('end', onend);
                            source.removeListener('close', onclose);

                            source.removeListener('error', onerror);
                            dest.removeListener('error', onerror);

                            source.removeListener('end', cleanup);
                            source.removeListener('close', cleanup);

                            dest.removeListener('close', cleanup);
                        }

                        source.on('end', cleanup);
                        source.on('close', cleanup);

                        dest.on('close', cleanup);

                        dest.emit('pipe', source);

                        // Allow for unix-like usage: A.pipe(B).pipe(C)
                        return dest;
                    };
                },
                {
                    events: 7,
                    inherits: 8,
                    'readable-stream/duplex.js': 11,
                    'readable-stream/passthrough.js': 18,
                    'readable-stream/readable.js': 19,
                    'readable-stream/transform.js': 20,
                    'readable-stream/writable.js': 21,
                },
            ],
            23: [
                function (require, module, exports) {
                    // Copyright Joyent, Inc. and other Node contributors.
                    //
                    // Permission is hereby granted, free of charge, to any person obtaining a
                    // copy of this software and associated documentation files (the
                    // "Software"), to deal in the Software without restriction, including
                    // without limitation the rights to use, copy, modify, merge, publish,
                    // distribute, sublicense, and/or sell copies of the Software, and to permit
                    // persons to whom the Software is furnished to do so, subject to the
                    // following conditions:
                    //
                    // The above copyright notice and this permission notice shall be included
                    // in all copies or substantial portions of the Software.
                    //
                    // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                    // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                    // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                    // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                    // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                    // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                    // USE OR OTHER DEALINGS IN THE SOFTWARE.

                    var Buffer = require('buffer').Buffer;

                    var isBufferEncoding =
                        Buffer.isEncoding ||
                        function (encoding) {
                            switch (encoding && encoding.toLowerCase()) {
                                case 'hex':
                                case 'utf8':
                                case 'utf-8':
                                case 'ascii':
                                case 'binary':
                                case 'base64':
                                case 'ucs2':
                                case 'ucs-2':
                                case 'utf16le':
                                case 'utf-16le':
                                case 'raw':
                                    return true;
                                default:
                                    return false;
                            }
                        };

                    function assertEncoding(encoding) {
                        if (encoding && !isBufferEncoding(encoding)) {
                            throw new Error('Unknown encoding: ' + encoding);
                        }
                    }

                    // StringDecoder provides an interface for efficiently splitting a series of
                    // buffers into a series of JS strings without breaking apart multi-byte
                    // characters. CESU-8 is handled as part of the UTF-8 encoding.
                    //
                    // @TODO Handling all encodings inside a single object makes it very difficult
                    // to reason about this code, so it should be split up in the future.
                    // @TODO There should be a utf8-strict encoding that rejects invalid UTF-8 code
                    // points as used by CESU-8.
                    var StringDecoder = (exports.StringDecoder = function (encoding) {
                        this.encoding = (encoding || 'utf8').toLowerCase().replace(/[-_]/, '');
                        assertEncoding(encoding);
                        switch (this.encoding) {
                            case 'utf8':
                                // CESU-8 represents each of Surrogate Pair by 3-bytes
                                this.surrogateSize = 3;
                                break;
                            case 'ucs2':
                            case 'utf16le':
                                // UTF-16 represents each of Surrogate Pair by 2-bytes
                                this.surrogateSize = 2;
                                this.detectIncompleteChar = utf16DetectIncompleteChar;
                                break;
                            case 'base64':
                                // Base-64 stores 3 bytes in 4 chars, and pads the remainder.
                                this.surrogateSize = 3;
                                this.detectIncompleteChar = base64DetectIncompleteChar;
                                break;
                            default:
                                this.write = passThroughWrite;
                                return;
                        }

                        // Enough space to store all bytes of a single character. UTF-8 needs 4
                        // bytes, but CESU-8 may require up to 6 (3 bytes per surrogate).
                        this.charBuffer = new Buffer(6);
                        // Number of bytes received for the current incomplete multi-byte character.
                        this.charReceived = 0;
                        // Number of bytes expected for the current incomplete multi-byte character.
                        this.charLength = 0;
                    });

                    // write decodes the given buffer and returns it as JS string that is
                    // guaranteed to not contain any partial multi-byte characters. Any partial
                    // character found at the end of the buffer is buffered up, and will be
                    // returned when calling write again with the remaining bytes.
                    //
                    // Note: Converting a Buffer containing an orphan surrogate to a String
                    // currently works, but converting a String to a Buffer (via `new Buffer`, or
                    // Buffer#write) will replace incomplete surrogates with the unicode
                    // replacement character. See https://codereview.chromium.org/121173009/ .
                    StringDecoder.prototype.write = function (buffer) {
                        var charStr = '';
                        // if our last write ended with an incomplete multibyte character
                        while (this.charLength) {
                            // determine how many remaining bytes this buffer has to offer for this char
                            var available =
                                buffer.length >= this.charLength - this.charReceived
                                    ? this.charLength - this.charReceived
                                    : buffer.length;

                            // add the new bytes to the char buffer
                            buffer.copy(this.charBuffer, this.charReceived, 0, available);
                            this.charReceived += available;

                            if (this.charReceived < this.charLength) {
                                // still not enough chars in this buffer? wait for more ...
                                return '';
                            }

                            // remove bytes belonging to the current character from the buffer
                            buffer = buffer.slice(available, buffer.length);

                            // get the character that was split
                            charStr = this.charBuffer.slice(0, this.charLength).toString(this.encoding);

                            // CESU-8: lead surrogate (D800-DBFF) is also the incomplete character
                            var charCode = charStr.charCodeAt(charStr.length - 1);
                            if (charCode >= 0xd800 && charCode <= 0xdbff) {
                                this.charLength += this.surrogateSize;
                                charStr = '';
                                continue;
                            }
                            this.charReceived = this.charLength = 0;

                            // if there are no more bytes in this buffer, just emit our char
                            if (buffer.length === 0) {
                                return charStr;
                            }
                            break;
                        }

                        // determine and set charLength / charReceived
                        this.detectIncompleteChar(buffer);

                        var end = buffer.length;
                        if (this.charLength) {
                            // buffer the incomplete character bytes we got
                            buffer.copy(this.charBuffer, 0, buffer.length - this.charReceived, end);
                            end -= this.charReceived;
                        }

                        charStr += buffer.toString(this.encoding, 0, end);

                        var end = charStr.length - 1;
                        var charCode = charStr.charCodeAt(end);
                        // CESU-8: lead surrogate (D800-DBFF) is also the incomplete character
                        if (charCode >= 0xd800 && charCode <= 0xdbff) {
                            var size = this.surrogateSize;
                            this.charLength += size;
                            this.charReceived += size;
                            this.charBuffer.copy(this.charBuffer, size, 0, size);
                            buffer.copy(this.charBuffer, 0, 0, size);
                            return charStr.substring(0, end);
                        }

                        // or just emit the charStr
                        return charStr;
                    };

                    // detectIncompleteChar determines if there is an incomplete UTF-8 character at
                    // the end of the given buffer. If so, it sets this.charLength to the byte
                    // length that character, and sets this.charReceived to the number of bytes
                    // that are available for this character.
                    StringDecoder.prototype.detectIncompleteChar = function (buffer) {
                        // determine how many bytes we have to check at the end of this buffer
                        var i = buffer.length >= 3 ? 3 : buffer.length;

                        // Figure out if one of the last i bytes of our buffer announces an
                        // incomplete char.
                        for (; i > 0; i--) {
                            var c = buffer[buffer.length - i];

                            // See http://en.wikipedia.org/wiki/UTF-8#Description

                            // 110XXXXX
                            if (i == 1 && c >> 5 == 0x06) {
                                this.charLength = 2;
                                break;
                            }

                            // 1110XXXX
                            if (i <= 2 && c >> 4 == 0x0e) {
                                this.charLength = 3;
                                break;
                            }

                            // 11110XXX
                            if (i <= 3 && c >> 3 == 0x1e) {
                                this.charLength = 4;
                                break;
                            }
                        }
                        this.charReceived = i;
                    };

                    StringDecoder.prototype.end = function (buffer) {
                        var res = '';
                        if (buffer && buffer.length) res = this.write(buffer);

                        if (this.charReceived) {
                            var cr = this.charReceived;
                            var buf = this.charBuffer;
                            var enc = this.encoding;
                            res += buf.slice(0, cr).toString(enc);
                        }

                        return res;
                    };

                    function passThroughWrite(buffer) {
                        return buffer.toString(this.encoding);
                    }

                    function utf16DetectIncompleteChar(buffer) {
                        this.charReceived = buffer.length % 2;
                        this.charLength = this.charReceived ? 2 : 0;
                    }

                    function base64DetectIncompleteChar(buffer) {
                        this.charReceived = buffer.length % 3;
                        this.charLength = this.charReceived ? 3 : 0;
                    }
                },
                { buffer: 3 },
            ],
            24: [
                function (require, module, exports) {
                    module.exports = function isBuffer(arg) {
                        return (
                            arg &&
                            typeof arg === 'object' &&
                            typeof arg.copy === 'function' &&
                            typeof arg.fill === 'function' &&
                            typeof arg.readUInt8 === 'function'
                        );
                    };
                },
                {},
            ],
            25: [
                function (require, module, exports) {
                    (function (process, global) {
                        // Copyright Joyent, Inc. and other Node contributors.
                        //
                        // Permission is hereby granted, free of charge, to any person obtaining a
                        // copy of this software and associated documentation files (the
                        // "Software"), to deal in the Software without restriction, including
                        // without limitation the rights to use, copy, modify, merge, publish,
                        // distribute, sublicense, and/or sell copies of the Software, and to permit
                        // persons to whom the Software is furnished to do so, subject to the
                        // following conditions:
                        //
                        // The above copyright notice and this permission notice shall be included
                        // in all copies or substantial portions of the Software.
                        //
                        // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
                        // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                        // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
                        // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
                        // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                        // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
                        // USE OR OTHER DEALINGS IN THE SOFTWARE.

                        var formatRegExp = /%[sdj%]/g;
                        exports.format = function (f) {
                            if (!isString(f)) {
                                var objects = [];
                                for (var i = 0; i < arguments.length; i++) {
                                    objects.push(inspect(arguments[i]));
                                }
                                return objects.join(' ');
                            }

                            var i = 1;
                            var args = arguments;
                            var len = args.length;
                            var str = String(f).replace(formatRegExp, function (x) {
                                if (x === '%%') return '%';
                                if (i >= len) return x;
                                switch (x) {
                                    case '%s':
                                        return String(args[i++]);
                                    case '%d':
                                        return Number(args[i++]);
                                    case '%j':
                                        try {
                                            return JSON.stringify(args[i++]);
                                        } catch (_) {
                                            return '[Circular]';
                                        }
                                    default:
                                        return x;
                                }
                            });
                            for (var x = args[i]; i < len; x = args[++i]) {
                                if (isNull(x) || !isObject(x)) {
                                    str += ' ' + x;
                                } else {
                                    str += ' ' + inspect(x);
                                }
                            }
                            return str;
                        };

                        // Mark that a method should not be used.
                        // Returns a modified function which warns once by default.
                        // If --no-deprecation is set, then it is a no-op.
                        exports.deprecate = function (fn, msg) {
                            // Allow for deprecating things in the process of starting up.
                            if (isUndefined(global.process)) {
                                return function () {
                                    return exports.deprecate(fn, msg).apply(this, arguments);
                                };
                            }

                            if (process.noDeprecation === true) {
                                return fn;
                            }

                            var warned = false;
                            function deprecated() {
                                if (!warned) {
                                    if (process.throwDeprecation) {
                                        throw new Error(msg);
                                    } else if (process.traceDeprecation) {
                                        console.trace(msg);
                                    } else {
                                        console.error(msg);
                                    }
                                    warned = true;
                                }
                                return fn.apply(this, arguments);
                            }

                            return deprecated;
                        };

                        var debugs = {};
                        var debugEnviron;
                        exports.debuglog = function (set) {
                            if (isUndefined(debugEnviron)) debugEnviron = process.env.NODE_DEBUG || '';
                            set = set.toUpperCase();
                            if (!debugs[set]) {
                                if (new RegExp('\\b' + set + '\\b', 'i').test(debugEnviron)) {
                                    var pid = process.pid;
                                    debugs[set] = function () {
                                        var msg = exports.format.apply(exports, arguments);
                                        console.error('%s %d: %s', set, pid, msg);
                                    };
                                } else {
                                    debugs[set] = function () {};
                                }
                            }
                            return debugs[set];
                        };

                        /**
                         * Echos the value of a value. Trys to print the value out
                         * in the best way possible given the different types.
                         *
                         * @param {Object} obj The object to print out.
                         * @param {Object} opts Optional options object that alters the output.
                         */
                        /* legacy: obj, showHidden, depth, colors*/
                        function inspect(obj, opts) {
                            // default options
                            var ctx = {
                                seen: [],
                                stylize: stylizeNoColor,
                            };
                            // legacy...
                            if (arguments.length >= 3) ctx.depth = arguments[2];
                            if (arguments.length >= 4) ctx.colors = arguments[3];
                            if (isBoolean(opts)) {
                                // legacy...
                                ctx.showHidden = opts;
                            } else if (opts) {
                                // got an "options" object
                                exports._extend(ctx, opts);
                            }
                            // set default options
                            if (isUndefined(ctx.showHidden)) ctx.showHidden = false;
                            if (isUndefined(ctx.depth)) ctx.depth = 2;
                            if (isUndefined(ctx.colors)) ctx.colors = false;
                            if (isUndefined(ctx.customInspect)) ctx.customInspect = true;
                            if (ctx.colors) ctx.stylize = stylizeWithColor;
                            return formatValue(ctx, obj, ctx.depth);
                        }
                        exports.inspect = inspect;

                        // http://en.wikipedia.org/wiki/ANSI_escape_code#graphics
                        inspect.colors = {
                            bold: [1, 22],
                            italic: [3, 23],
                            underline: [4, 24],
                            inverse: [7, 27],
                            white: [37, 39],
                            grey: [90, 39],
                            black: [30, 39],
                            blue: [34, 39],
                            cyan: [36, 39],
                            green: [32, 39],
                            magenta: [35, 39],
                            red: [31, 39],
                            yellow: [33, 39],
                        };

                        // Don't use 'blue' not visible on cmd.exe
                        inspect.styles = {
                            special: 'cyan',
                            number: 'yellow',
                            boolean: 'yellow',
                            undefined: 'grey',
                            null: 'bold',
                            string: 'green',
                            date: 'magenta',
                            // "name": intentionally not styling
                            regexp: 'red',
                        };

                        function stylizeWithColor(str, styleType) {
                            var style = inspect.styles[styleType];

                            if (style) {
                                return (
                                    '\u001b[' +
                                    inspect.colors[style][0] +
                                    'm' +
                                    str +
                                    '\u001b[' +
                                    inspect.colors[style][1] +
                                    'm'
                                );
                            } else {
                                return str;
                            }
                        }

                        function stylizeNoColor(str, styleType) {
                            return str;
                        }

                        function arrayToHash(array) {
                            var hash = {};

                            array.forEach(function (val, idx) {
                                hash[val] = true;
                            });

                            return hash;
                        }

                        function formatValue(ctx, value, recurseTimes) {
                            // Provide a hook for user-specified inspect functions.
                            // Check that value is an object with an inspect function on it
                            if (
                                ctx.customInspect &&
                                value &&
                                isFunction(value.inspect) &&
                                // Filter out the util module, it's inspect function is special
                                value.inspect !== exports.inspect &&
                                // Also filter out any prototype objects using the circular check.
                                !(value.constructor && value.constructor.prototype === value)
                            ) {
                                var ret = value.inspect(recurseTimes, ctx);
                                if (!isString(ret)) {
                                    ret = formatValue(ctx, ret, recurseTimes);
                                }
                                return ret;
                            }

                            // Primitive types cannot have properties
                            var primitive = formatPrimitive(ctx, value);
                            if (primitive) {
                                return primitive;
                            }

                            // Look up the keys of the object.
                            var keys = Object.keys(value);
                            var visibleKeys = arrayToHash(keys);

                            if (ctx.showHidden) {
                                keys = Object.getOwnPropertyNames(value);
                            }

                            // IE doesn't make error fields non-enumerable
                            // http://msdn.microsoft.com/en-us/library/ie/dww52sbt(v=vs.94).aspx
                            if (isError(value) && (keys.indexOf('message') >= 0 || keys.indexOf('description') >= 0)) {
                                return formatError(value);
                            }

                            // Some type of object without properties can be shortcutted.
                            if (keys.length === 0) {
                                if (isFunction(value)) {
                                    var name = value.name ? ': ' + value.name : '';
                                    return ctx.stylize('[Function' + name + ']', 'special');
                                }
                                if (isRegExp(value)) {
                                    return ctx.stylize(RegExp.prototype.toString.call(value), 'regexp');
                                }
                                if (isDate(value)) {
                                    return ctx.stylize(Date.prototype.toString.call(value), 'date');
                                }
                                if (isError(value)) {
                                    return formatError(value);
                                }
                            }

                            var base = '',
                                array = false,
                                braces = ['{', '}'];

                            // Make Array say that they are Array
                            if (isArray(value)) {
                                array = true;
                                braces = ['[', ']'];
                            }

                            // Make functions say that they are functions
                            if (isFunction(value)) {
                                var n = value.name ? ': ' + value.name : '';
                                base = ' [Function' + n + ']';
                            }

                            // Make RegExps say that they are RegExps
                            if (isRegExp(value)) {
                                base = ' ' + RegExp.prototype.toString.call(value);
                            }

                            // Make dates with properties first say the date
                            if (isDate(value)) {
                                base = ' ' + Date.prototype.toUTCString.call(value);
                            }

                            // Make error with message first say the error
                            if (isError(value)) {
                                base = ' ' + formatError(value);
                            }

                            if (keys.length === 0 && (!array || value.length == 0)) {
                                return braces[0] + base + braces[1];
                            }

                            if (recurseTimes < 0) {
                                if (isRegExp(value)) {
                                    return ctx.stylize(RegExp.prototype.toString.call(value), 'regexp');
                                } else {
                                    return ctx.stylize('[Object]', 'special');
                                }
                            }

                            ctx.seen.push(value);

                            var output;
                            if (array) {
                                output = formatArray(ctx, value, recurseTimes, visibleKeys, keys);
                            } else {
                                output = keys.map(function (key) {
                                    return formatProperty(ctx, value, recurseTimes, visibleKeys, key, array);
                                });
                            }

                            ctx.seen.pop();

                            return reduceToSingleString(output, base, braces);
                        }

                        function formatPrimitive(ctx, value) {
                            if (isUndefined(value)) return ctx.stylize('undefined', 'undefined');
                            if (isString(value)) {
                                var simple =
                                    "'" +
                                    JSON.stringify(value)
                                        .replace(/^"|"$/g, '')
                                        .replace(/'/g, "\\'")
                                        .replace(/\\"/g, '"') +
                                    "'";
                                return ctx.stylize(simple, 'string');
                            }
                            if (isNumber(value)) return ctx.stylize('' + value, 'number');
                            if (isBoolean(value)) return ctx.stylize('' + value, 'boolean');
                            // For some reason typeof null is "object", so special case here.
                            if (isNull(value)) return ctx.stylize('null', 'null');
                        }

                        function formatError(value) {
                            return '[' + Error.prototype.toString.call(value) + ']';
                        }

                        function formatArray(ctx, value, recurseTimes, visibleKeys, keys) {
                            var output = [];
                            for (var i = 0, l = value.length; i < l; ++i) {
                                if (hasOwnProperty(value, String(i))) {
                                    output.push(formatProperty(ctx, value, recurseTimes, visibleKeys, String(i), true));
                                } else {
                                    output.push('');
                                }
                            }
                            keys.forEach(function (key) {
                                if (!key.match(/^\d+$/)) {
                                    output.push(formatProperty(ctx, value, recurseTimes, visibleKeys, key, true));
                                }
                            });
                            return output;
                        }

                        function formatProperty(ctx, value, recurseTimes, visibleKeys, key, array) {
                            var name, str, desc;
                            desc = Object.getOwnPropertyDescriptor(value, key) || { value: value[key] };
                            if (desc.get) {
                                if (desc.set) {
                                    str = ctx.stylize('[Getter/Setter]', 'special');
                                } else {
                                    str = ctx.stylize('[Getter]', 'special');
                                }
                            } else {
                                if (desc.set) {
                                    str = ctx.stylize('[Setter]', 'special');
                                }
                            }
                            if (!hasOwnProperty(visibleKeys, key)) {
                                name = '[' + key + ']';
                            }
                            if (!str) {
                                if (ctx.seen.indexOf(desc.value) < 0) {
                                    if (isNull(recurseTimes)) {
                                        str = formatValue(ctx, desc.value, null);
                                    } else {
                                        str = formatValue(ctx, desc.value, recurseTimes - 1);
                                    }
                                    if (str.indexOf('\n') > -1) {
                                        if (array) {
                                            str = str
                                                .split('\n')
                                                .map(function (line) {
                                                    return '  ' + line;
                                                })
                                                .join('\n')
                                                .substr(2);
                                        } else {
                                            str =
                                                '\n' +
                                                str
                                                    .split('\n')
                                                    .map(function (line) {
                                                        return '   ' + line;
                                                    })
                                                    .join('\n');
                                        }
                                    }
                                } else {
                                    str = ctx.stylize('[Circular]', 'special');
                                }
                            }
                            if (isUndefined(name)) {
                                if (array && key.match(/^\d+$/)) {
                                    return str;
                                }
                                name = JSON.stringify('' + key);
                                if (name.match(/^"([a-zA-Z_][a-zA-Z_0-9]*)"$/)) {
                                    name = name.substr(1, name.length - 2);
                                    name = ctx.stylize(name, 'name');
                                } else {
                                    name = name
                                        .replace(/'/g, "\\'")
                                        .replace(/\\"/g, '"')
                                        .replace(/(^"|"$)/g, "'");
                                    name = ctx.stylize(name, 'string');
                                }
                            }

                            return name + ': ' + str;
                        }

                        function reduceToSingleString(output, base, braces) {
                            var numLinesEst = 0;
                            var length = output.reduce(function (prev, cur) {
                                numLinesEst++;
                                if (cur.indexOf('\n') >= 0) numLinesEst++;
                                return prev + cur.replace(/\u001b\[\d\d?m/g, '').length + 1;
                            }, 0);

                            if (length > 60) {
                                return (
                                    braces[0] +
                                    (base === '' ? '' : base + '\n ') +
                                    ' ' +
                                    output.join(',\n  ') +
                                    ' ' +
                                    braces[1]
                                );
                            }

                            return braces[0] + base + ' ' + output.join(', ') + ' ' + braces[1];
                        }

                        // NOTE: These type checking functions intentionally don't use `instanceof`
                        // because it is fragile and can be easily faked with `Object.create()`.
                        function isArray(ar) {
                            return Array.isArray(ar);
                        }
                        exports.isArray = isArray;

                        function isBoolean(arg) {
                            return typeof arg === 'boolean';
                        }
                        exports.isBoolean = isBoolean;

                        function isNull(arg) {
                            return arg === null;
                        }
                        exports.isNull = isNull;

                        function isNullOrUndefined(arg) {
                            return arg == null;
                        }
                        exports.isNullOrUndefined = isNullOrUndefined;

                        function isNumber(arg) {
                            return typeof arg === 'number';
                        }
                        exports.isNumber = isNumber;

                        function isString(arg) {
                            return typeof arg === 'string';
                        }
                        exports.isString = isString;

                        function isSymbol(arg) {
                            return typeof arg === 'symbol';
                        }
                        exports.isSymbol = isSymbol;

                        function isUndefined(arg) {
                            return arg === void 0;
                        }
                        exports.isUndefined = isUndefined;

                        function isRegExp(re) {
                            return isObject(re) && objectToString(re) === '[object RegExp]';
                        }
                        exports.isRegExp = isRegExp;

                        function isObject(arg) {
                            return typeof arg === 'object' && arg !== null;
                        }
                        exports.isObject = isObject;

                        function isDate(d) {
                            return isObject(d) && objectToString(d) === '[object Date]';
                        }
                        exports.isDate = isDate;

                        function isError(e) {
                            return isObject(e) && (objectToString(e) === '[object Error]' || e instanceof Error);
                        }
                        exports.isError = isError;

                        function isFunction(arg) {
                            return typeof arg === 'function';
                        }
                        exports.isFunction = isFunction;

                        function isPrimitive(arg) {
                            return (
                                arg === null ||
                                typeof arg === 'boolean' ||
                                typeof arg === 'number' ||
                                typeof arg === 'string' ||
                                typeof arg === 'symbol' || // ES6 symbol
                                typeof arg === 'undefined'
                            );
                        }
                        exports.isPrimitive = isPrimitive;

                        exports.isBuffer = require('./support/isBuffer');

                        function objectToString(o) {
                            return Object.prototype.toString.call(o);
                        }

                        function pad(n) {
                            return n < 10 ? '0' + n.toString(10) : n.toString(10);
                        }

                        var months = [
                            'Jan',
                            'Feb',
                            'Mar',
                            'Apr',
                            'May',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Oct',
                            'Nov',
                            'Dec',
                        ];

                        // 26 Feb 16:19:34
                        function timestamp() {
                            var d = new Date();
                            var time = [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(':');
                            return [d.getDate(), months[d.getMonth()], time].join(' ');
                        }

                        // log is just a thin wrapper to console.log that prepends a timestamp
                        exports.log = function () {
                            console.log('%s - %s', timestamp(), exports.format.apply(exports, arguments));
                        };

                        /**
                         * Inherit the prototype methods from one constructor into another.
                         *
                         * The Function.prototype.inherits from lang.js rewritten as a standalone
                         * function (not on Function.prototype). NOTE: If this file is to be loaded
                         * during bootstrapping this function needs to be rewritten using some native
                         * functions as prototype setup using normal JavaScript does not work as
                         * expected during bootstrapping (see mirror.js in r114903).
                         *
                         * @param {function} ctor Constructor function which needs to inherit the
                         *     prototype.
                         * @param {function} superCtor Constructor function to inherit prototype from.
                         */
                        exports.inherits = require('inherits');

                        exports._extend = function (origin, add) {
                            // Don't do anything if add isn't an object
                            if (!add || !isObject(add)) return origin;

                            var keys = Object.keys(add);
                            var i = keys.length;
                            while (i--) {
                                origin[keys[i]] = add[keys[i]];
                            }
                            return origin;
                        };

                        function hasOwnProperty(obj, prop) {
                            return Object.prototype.hasOwnProperty.call(obj, prop);
                        }
                    }.call(
                        this,
                        require('_process'),
                        typeof global !== 'undefined'
                            ? global
                            : typeof self !== 'undefined'
                            ? self
                            : typeof window !== 'undefined'
                            ? window
                            : {},
                    ));
                },
                { './support/isBuffer': 24, _process: 10, inherits: 8 },
            ],
        },
        {},
        [2],
    )(2);
});
